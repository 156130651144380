import { get } from "lodash";

import { createErrorBody } from "../utils/joiReduxForm";

export const totalWeightValidation = props => {
  const totalWeightValue = get(props, "values.returnFromTotalWeight");
  const numberOfParcels = get(props, "values.returnFromNumberOfParcels");

  if (!totalWeightValue || !numberOfParcels) {
    return;
  }

  if (parseFloat(totalWeightValue) / numberOfParcels > 20) {
    return createErrorBody(
      "returnFromTotalWeight",
      "Each parcel has a max weight of 20kgs"
    );
  }
};
