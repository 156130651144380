import printJS from "@dpdgroupuk/mydpd-app/lib/features/Printer/printJS";

export const printLabel = label => {
  const breakLineRegex = /<\/br>/g;

  return printJS({
    printable: label.replace(breakLineRegex, ""),
    type: "raw-html",
  });
};
