// WATCHLIST PROMPT
export const WATCHLIST_PROMPT_ERROR =
  "We cannot complete this action right now, please try again";
export const ADD_TO_WATCHLIST_PROMPT_HEADER = "Add To Watchlist";
export const ADD_TO_WATCHLIST_PROMPT_MESSAGE =
  "Add this parcel to the watch list";
export const REMOVE_FROM_WATCHLIST_PROMPT_HEADER = "Remove From Watchlist";
export const REMOVE_FROM_WATCHLIST_PROMPT_MESSAGE =
  "Are you sure you would like to remove parcel from watchlist?";

// HELP MODALS
export const ACCOUNT_SEARCH_HELPER_MODAL = [
  {
    name: "name 1",
    content: (
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus enim
        et exercitationem facilis, iste, laborum nemo non quam quisquam ratione
        repellat sed tempore tenetur ullam voluptatum! Ab dicta quidem tempora.
      </div>
    ),
  },
  {
    name: "name 2",
    content: (
      <div>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus enim
        et exercitationem facilis, iste, laborum nemo non quam quisquam ratione
        repellat sed tempore tenetur ullam voluptatum! Ab dicta quidem tempora.
      </div>
    ),
  },
];
