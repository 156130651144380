const CivilUnrest = props => (
  <svg
    id="Civil-unrest-1"
    viewBox="0 0 46 32"
    {...props}
    width="46px"
    height="32px"
  >
    <path d="M45.504 25.632c0 2.88-2.56 4-2.56 1.568 0-0.192 0.032-0.384-0.032-0.544s-0.224-0.224-0.352-0.352l-0.16 0.096v0.864c0 5.376-4.32 4.608-7.296 4.608-1.344 0-0.96-4.928-0.96-6.912-0.008-0.415-0.126-0.8-0.326-1.13l0.006 0.010c-1.92-3.104-3.84-6.144-5.76-9.216-0.672-1.12-0.576-1.792 0.416-2.656 0.111-0.086 0.192-0.208 0.223-0.348l0.001-0.004c0-1.088 0-2.144 0-3.296h-6.112v-8.32h13.44v8.288h-6.144c0 1.184 0 2.24 0 3.328 0.030 0.102 0.085 0.189 0.16 0.256l0 0c0.352 0.448 0.8 0.832 1.088 1.312q2.528 3.968 4.96 7.968c0.256 0.416 0.512 0.544 0.992 0.512 1.504 0 3.008 0 4.512 0 2.272 0.032 3.872 1.664 3.904 3.968zM18.752 9.792c-0.928-2.4-1.824-4.704-2.752-7.040l-0.992 0.384c0.928 2.4 1.824 4.704 2.752 7.040zM38.944 21.088c1.654-0.018 2.99-1.354 3.008-3.006v-0.002c0.032-1.632-1.376-3.008-3.008-3.008-1.661 0-3.008 1.347-3.008 3.008s1.347 3.008 3.008 3.008v0zM16.384 11.296l-6.272-4.096-0.576 0.896 6.24 4.096c0.224-0.288 0.384-0.576 0.608-0.896zM13.824 15.072c-0.019-0-0.041-0.001-0.064-0.001-1.661 0-3.008 1.347-3.008 3.008s1.347 3.008 3.008 3.008c1.65 0 2.989-1.328 3.008-2.974v-0.002c0-0.019 0.001-0.042 0.001-0.064 0-1.633-1.315-2.958-2.943-2.976h-0.002zM24.256 12c-0.864-0.544-1.792-0.256-2.432 0.768-1.568 2.464-3.168 4.928-4.608 7.456-0.672 1.152-1.408 1.632-2.72 1.44-1.344-0.16-2.592 0.16-3.84 0.704s-2.56 0.992-3.84 1.536c-0.448 0.192-0.736 0.16-1.024-0.32-0.832-1.344-1.696-2.624-2.592-3.936-0.608-0.928-1.6-1.184-2.432-0.672s-1.024 1.568-0.416 2.496q1.824 2.784 3.68 5.568c0.576 0.832 1.28 1.056 2.24 0.704 1.12-0.416 2.24-0.864 3.392-1.312 0.192-0.064 0.384-0.128 0.64-0.224 0 0.32 0.352 2.88 0.352 3.104l8.288-0.288c0-0.416-0.224-4.896 0-5.28 1.888-3.072 3.808-6.144 5.728-9.216 0.608-0.992 0.448-1.984-0.416-2.528z" />
  </svg>
);
export default CivilUnrest;
