import Joi from "joi";

import { ACCOUNT_SEARCH_TYPES } from "../constants/forms";
import {
  account,
  fromDate,
  parcelNumber,
  postcode,
  searchByType,
  toDate,
} from "./commonSchema";

export default Joi.object().keys({
  account,
  searchByType,
  searchByValue: Joi.when("$searchByType", {
    is: ACCOUNT_SEARCH_TYPES.PARCEL_NUMBER,
    then: parcelNumber,
    otherwise: Joi.when("$searchByType", {
      is: ACCOUNT_SEARCH_TYPES.RETURNS_REFERENCE,
      then: Joi.string()
        .label(ACCOUNT_SEARCH_TYPES.RETURNS_REFERENCE)
        .required(),
    }),
  }),
  searchByFromDate: Joi.when("$searchByType", {
    is: ACCOUNT_SEARCH_TYPES.DATE_SEARCH,
    then: fromDate,
  }),
  searchByToDate: Joi.when("$searchByType", {
    is: ACCOUNT_SEARCH_TYPES.DATE_SEARCH,
    then: toDate,
  }),
  searchByPostcode: Joi.when("$searchByType", {
    is: ACCOUNT_SEARCH_TYPES.DATE_SEARCH,
    then: postcode,
  }),
});
