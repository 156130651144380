import Joi from "joi";
import { get } from "lodash";

import {
  address1,
  address2,
  city,
  country,
  county,
  deliveryContactName,
  deliveryOrganisation,
  email,
  name,
  numberOfParcels,
  organisation,
  postcode,
  returnReference,
  shortName,
  telephone,
  totalWeight,
} from "./commonSchema";

export const createReturnSchema = (props, values) => {
  const { isPostcodeRequired } = get(props, "selectedCountry", "");
  const { returnToContactName, returnToOrganisation } = values;

  return Joi.object().keys({
    returnFromShortName: shortName,
    returnFromCountry: country,
    returnFromOrganisation: organisation,
    returnFromAddress1: address1,
    returnFromAddress2: address2,
    returnFromCity: city,
    returnFromCounty: county,
    returnFromName: name,
    returnReference,
    returnFromPostcode: isPostcodeRequired ? postcode.required() : postcode,
    returnToPostcode: isPostcodeRequired ? postcode.required() : postcode,
    returnFromTelephone: telephone,
    returnFromEmail: email,
    returnFromTotalWeight: totalWeight,
    returnFromNumberOfParcels: numberOfParcels,
    returnToShortName: shortName,
    returnToCountry: country,
    returnToOrganisation: !returnToContactName
      ? deliveryOrganisation.required()
      : deliveryOrganisation,
    returnToAddress1: address1,
    returnToAddress2: address2,
    returnToCity: city,
    returnToCounty: county,
    returnToContactName: !returnToOrganisation
      ? deliveryContactName.required()
      : deliveryContactName,
  });
};
