import PropTypes from "prop-types";

import { ParcelAdditionalStatus } from "../ParcelAdditionalStatus/ParcelAdditionalStatus";
import { ReturnStatus } from "../ReturnStatus/ReturnStatus";

export const IssueParcelView = ({ parcel }) => {
  return (
    <>
      <ReturnStatus parcel={parcel} />
      <ParcelAdditionalStatus parcel={parcel} />
    </>
  );
};

IssueParcelView.propTypes = {
  parcel: PropTypes.object,
};
