import { useCallback } from "react";

import PropTypes from "prop-types";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";

export const PopupModal = ({ showModal, setShowModal, children }) => {
  const onClose = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <Modal show={showModal} onHide={onClose}>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button className="button" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  children: PropTypes.node,
};
