import { connect } from "react-redux";
import { lifecycle } from "recompose";
import { compose } from "redux";

import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { withLoader } from "@dpdgroupuk/mydpd-ui";

import { fetchAddressBookTotalResults } from "../../../apis/addressBook";
import { addFuncIdToLog } from "../../../apis/functionalityLog";
import { AddressBookActions, AuthSelectors } from "../../../redux";
import { CreateReturnActions } from "../../../redux/createReturn";
import ReturnForm from "./components/ReturnForm/ReturnForm";

const CreateReturn = () => {
  return <ReturnForm />;
};

export default compose(
  withAppUserPreferences,
  connect(
    state => ({
      user: AuthSelectors.getAuthUser(state),
    }),
    dispatch => ({
      fetchProfiles: userId =>
        dispatch(CreateReturnActions.fetchProfiles(userId)),
      fetchPickupCountries: userId =>
        dispatch(CreateReturnActions.fetchPickupCountries(userId)),
      fetchAddressBook: pageSize =>
        dispatch(AddressBookActions.fetchAddressBook(pageSize)),
      fetchDefaultAddress: () =>
        dispatch(AddressBookActions.fetchDefaultAddress()),
      clearAddressBook: () => dispatch(AddressBookActions.clearAddressBook()),
      resetCreateReturn: () =>
        dispatch(CreateReturnActions.resetCreateReturn()),
    })
  ),
  withLoader({
    loadFn: async ({
      user,
      fetchProfiles,
      fetchPickupCountries,
      fetchAddressBook,
      fetchDefaultAddress,
    }) => {
      const { userId } = user;
      await fetchProfiles(encodeURI(userId));
      await fetchPickupCountries({ dropOffAvailable: true });

      const { data } = await fetchAddressBookTotalResults();

      try {
        await fetchDefaultAddress();
      } catch (error) {
        // Ignore the error as there is no default address
      }

      // search addressbook only if total results > 0
      if (data.totalResults > 0) {
        await fetchAddressBook(data.totalResults);
      }

      await Promise.all([
        addFuncIdToLog("cf7d6873d04749279a6c94326b057200"),
        addFuncIdToLog("33469705df9d47189d2d2be6fd4bd811"),
      ]);
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.resetCreateReturn();
      this.props.clearAddressBook();
    },
  })
)(CreateReturn);
