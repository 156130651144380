import { isEmpty, pick, valuesIn } from "lodash";
import queryString from "query-string";

import { FILTER_FORM_FIELDS } from "../constants/forms";
import { omitNilValues } from "./object";

export const INITIAL_PAGE_QUERY = { page: 1, pageSize: 25 };

export const parseQuery = (location, parseNumbers) => {
  const query = queryString.parse(location.search, {
    parseNumbers,
  });

  return {
    ...INITIAL_PAGE_QUERY,
    ...query,
  };
};

export const getFilters = location => {
  const query = parseQuery(location);
  const res = pick(query, valuesIn(FILTER_FORM_FIELDS));
  return isEmpty(res) ? null : omitNilValues(res);
};

export const getSearchQuery = location => {
  const query = parseQuery(location);
  const res = pick(query, [
    "searchParam",
    "account",
    "accountNumber",
    "returnsReference",
    "deliveryDate",
    "shipmentCreateDate",
    "searchDays",
    "parcelNumber",
    "postcode",
    "pot",
    "businessUnit",
  ]);

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getPageQuery = location => {
  const query = parseQuery(location);
  const res = pick(query, ["page"]);

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getPageToStartPos = (page, findByTotal) => {
  const { pageSize } = INITIAL_PAGE_QUERY;
  const endPos = parseInt(page) * pageSize;

  if (parseInt(page) === 1) {
    return 1;
  }

  // This means that we are on the last paginated page
  if (endPos > findByTotal) {
    return findByTotal - pageSize + 1;
  }

  return (parseInt(page) - 1) * pageSize + 1;
};

export const getPageToEndPos = (page, findByTotal) => {
  const { pageSize } = INITIAL_PAGE_QUERY;
  const endPos = parseInt(page) * pageSize;

  // This means that we are on the last paginated page
  if (endPos > findByTotal) {
    return findByTotal;
  }

  return endPos;
};

export const stringifyQuery = (query, encode = true) =>
  queryString.stringify(query, {
    encode,
  });

export const isValidURL = str => {
  try {
    const url = new URL(str);
    return !!url;
  } catch (e) {
    return false;
  }
};

export const getExportCSVLink = findByCode =>
  `${process.env.REACT_APP_API_URL}/v1/returns/csv?key=${findByCode}`;
