import PropTypes from "prop-types";

import { ParcelDetails } from "../ParcelDetails/ParcelDetails";
import { ReturnStatus } from "../ReturnStatus/ReturnStatus";

export const GeneralParcelView = ({ parcel }) => (
  <>
    <ReturnStatus parcel={parcel} />
    <ParcelDetails data={parcel} />
  </>
);

GeneralParcelView.propTypes = {
  parcel: PropTypes.object,
};
