import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import styles from "../ParcelAdditionalStatus.module.scss";

export const ReasonStatus = ({
  rfiReasonDescription,
  rfiReasonExplanation,
}) => {
  let rfiMessage = "";

  if (!isEmpty(rfiReasonDescription)) {
    rfiMessage += rfiReasonDescription;
  }

  if (!isEmpty(rfiReasonExplanation)) {
    rfiMessage += `: ${rfiReasonExplanation}`;
  }

  return (
    <div className={styles.reasonStatus}>
      <span className={styles.errorMessage}>
        {" "}
        {"Further Instructions are required for this parcel"}
      </span>
      {rfiMessage}
    </div>
  );
};

ReasonStatus.propTypes = {
  rfiReasonDescription: PropTypes.string,
  rfiReasonExplanation: PropTypes.string,
};
