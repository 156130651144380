import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Field } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import { formatProfiles } from "../../../../../utils/format";

export const ReturnProfiles = () => {
  const { profiles } = useSelector(state => state.ui.createReturn);

  return (
    <Step>
      <Row>
        <Col md={5}>
          <p className="mydpd-card-text mydpd-card-step-text card-text">
            Shipping Profile
          </p>
        </Col>
        <Col md={7}>
          <Field
            component={FormControl.Dropdown}
            label="Profile"
            name="profileCode"
            values={formatProfiles(profiles.results)}
          />
        </Col>
      </Row>
    </Step>
  );
};
