import PropTypes from "prop-types";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

const DoubleAstericksInput = ({ label, ...props }) => (
  <FormControl.Input
    label={`${label} ${props.required ? "*" : ""}`}
    {...props}
  />
);

DoubleAstericksInput.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default DoubleAstericksInput;
