import { useMemo } from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import styles from "./AccountDropdown.module.scss";

export const AccountDropdown = ({
  accounts,
  required,
  name,
  onChange,
  useContainer,
}) => {
  const { customers } = useSelector(state => state.app.ums);

  const customerAccounts = useMemo(() => {
    const customerAccountsArr = [...customers];

    if (accounts.includes("All Accounts")) {
      customerAccountsArr.unshift({
        customername: "All Accounts",
        account: "All Accounts",
      });
    }
    return customerAccountsArr.map(customer => {
      if (customer.customername === "All Accounts") {
        return {
          label: `${customer.customername}`,
          value: customer.account,
        };
      } else {
        return {
          label: `${customer.customername} (${customer.account})`,
          value: customer.account,
        };
      }
    });
  }, [customers]);

  return (
    <div className={useContainer ? styles.accountDropdownContainer : null}>
      <Field
        component={FormControl.Dropdown}
        label="Account"
        name={name}
        values={customerAccounts}
        textTransform="uppercase"
        required={required}
        onChange={onChange}
      />
    </div>
  );
};

AccountDropdown.propTypes = {
  accounts: PropTypes.array,
  required: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  useContainer: PropTypes.bool,
};

AccountDropdown.defaultProps = {
  required: false,
  name: "account",
  onChange: null,
  useContainer: true,
};
