import moment from "moment-timezone";

export const formatReturn = (
  formValues,
  profileCode,
  countryCode,
  businessId
) => {
  return {
    outboundConsignment: {
      deliveryDetails: {
        address: {
          countryCode, // need country code for return to
          postcode: formValues.returnToPostcode,
          organisation: formValues?.returnToOrganisation,
          street: formValues.returnToAddress1,
          locality: formValues?.returnToAddress2,
          town: formValues.returnToCity,
          county: formValues?.returnToCounty,
        },
        contactDetails: {
          contactName: formValues?.returnToContactName,
        },
      },
      collectionDetails: {
        address: {
          countryCode: formValues.returnFromCountry,
          postcode: formValues.returnFromPostcode,
          organisation: formValues?.returnFromOrganisation,
          street: formValues.returnFromAddress1,
          locality: formValues?.returnFromAddress2,
          town: formValues.returnFromCity,
          county: formValues?.returnFromCounty,
        },
        contactDetails: {
          contactName: formValues?.returnFromName,
          email: formValues?.returnFromEmail,
          telephone: formValues?.returnFromTelephone,
        },
      },
      numberOfParcels: formValues.returnFromNumberOfParcels,
      totalWeight: formValues.returnFromTotalWeight,
      networkCode: `${businessId}^22`, // shop returns network code
      shippingRef1: formValues.returnReference,
    },
    shipmentDate: moment().format("YYYY-MM-DD"),
    profileCode,
  };
};
