import { useEffect } from "react";

import { camelCase, isEmpty, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { withProps } from "recompose";
import { compose } from "redux";
import { change, Field, formValueSelector, reduxForm } from "redux-form";

import {
  Banner,
  Card,
  FormControl,
  Step,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import { AccountDropdown } from "../../../../../components/AccountDropdown/AccountDropdown";
import PostcodeFinderInput from "../../../../../components/PostcodeFinderInput/PostcodeFinderInput";
import {
  ACCOUNT_SEARCH_FORM,
  ACCOUNT_SEARCH_TYPES,
  SEARCH_BY_DROPDOWN,
  SEARCH_BY_FIELDS,
} from "../../../../../constants/forms";
import searchSchema from "../../../../../models/searchSchema";
import {
  AuthSelectors,
  ParcelViewActions,
  ReturnActions,
} from "../../../../../redux";
import { RETURNS, SEARCH } from "../../../../../router/constants";
import {
  convertStringToDate,
  createMaxDate,
  diffInDays,
  formatDate,
} from "../../../../../utils/date";
import { formatAccounts } from "../../../../../utils/format";
import createValidator from "../../../../../utils/joiReduxForm";
import { stringifyQuery } from "../../../../../utils/query";
import { SubmitButton } from "../SubmitButton/SubmitButton";

const AccountSearch = ({
  handleSubmit,
  account,
  searchByType,
  searchByValue,
  searchByFromDate,
  searchByToDate,
  searchByPostcode,
  dispatch,
  banner,
  overlay,
  accounts,
  fetchParcelReturns,
  setAccount,
  setSearchFindbyCode,
  setFindby,
  fetchParcelCodes,
  user,
  fetchParcelByParcelCode,
  fetchRelatedParcels,
}) => {
  const history = useHistory();

  useEffect(() => {
    const searchByInput = document.querySelector("input[name='searchByValue']");
    if (searchByInput) searchByInput.focus();
    resetForm();
  }, [searchByType]);

  useEffect(() => {
    dispatch(change(ACCOUNT_SEARCH_FORM, "searchByToDate", ""));
  }, [searchByFromDate]);

  const resetForm = () => {
    // Reset input field values
    SEARCH_BY_FIELDS.forEach(field =>
      dispatch(change(ACCOUNT_SEARCH_FORM, field, ""))
    );
  };

  const handleSearchSubmit = async () => {
    overlay.show();

    const query = { account, businessUnit: user.businessId };

    if (searchByType === ACCOUNT_SEARCH_TYPES.DATE_SEARCH) {
      query.postcode = searchByPostcode;
      query.shipmentCreateDate = formatDate(searchByFromDate);
      query.searchDays = diffInDays(searchByFromDate, searchByToDate);
    } else {
      query[camelCase(searchByType)] = searchByValue;
    }

    try {
      if (account === "All Accounts") {
        await setAccount(accounts);
        query.account = accounts;
      } else {
        await setAccount(account);
      }

      const parcel = await fetchParcelReturns(omitBy(query, isNil));
      await setSearchFindbyCode(parcel.findby_code);
      await setFindby(parcel.findbyCode, parcel.findByTotal);

      if (!isEmpty(parcel)) {
        overlay.hide();

        const from = { from: "search" };
        if (parcel.findby_total === 1) {
          const { parcelCodes } = await fetchParcelCodes({
            findByCode: parcel.findby_code,
            startPos: 1,
            endPos: 25,
          });

          await fetchParcelByParcelCode({
            parcelCode: parcelCodes[0],
          });

          await fetchRelatedParcels(parcelCodes[0]);

          history.push(`${SEARCH}/${parcelCodes[0]}`, from);
        } else {
          const queryParams = stringifyQuery({ ...query });
          history.push(`${RETURNS}?${queryParams}`, from);
        }
      }
    } catch (error) {
      overlay.hide();
      banner.removeAll();
      banner.showByType("alert", {
        message: "No deliveries found",
        closable: true,
        showIcon: true,
        actions: [],
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSearchSubmit)} className="search-form">
      <Card.Stack fluid>
        <Col md={4}>
          <Step title="Select Account" style={{ height: "165px" }}>
            <AccountDropdown
              accounts={["All Accounts", ...accounts]}
              required={true}
            />
          </Step>
        </Col>
        <Col md={8}>
          <Step title="Search By" helpModalTitle={"Shop Returns Search"}>
            <Row>
              <Col md={4} className="searchByColumn">
                <Field
                  component={FormControl.Dropdown}
                  name="searchByType"
                  label="Search By"
                  values={SEARCH_BY_DROPDOWN}
                  textTransform="uppercase"
                  required
                />
              </Col>
              {searchByType &&
                searchByType !== ACCOUNT_SEARCH_TYPES.DATE_SEARCH && (
                  <Col md={4}>
                    <Field
                      component={FormControl.Input}
                      name={"searchByValue"}
                      label={searchByType}
                      value={searchByValue}
                      helperText={" "}
                      maxLength={searchByType === "Parcel Number" ? 14 : null}
                    />
                  </Col>
                )}
              {searchByType &&
                searchByType === ACCOUNT_SEARCH_TYPES.DATE_SEARCH && (
                  <>
                    <Col md={4}>
                      <Field
                        component={FormControl.DatePicker}
                        name="searchByFromDate"
                        label="From Date"
                        value={searchByFromDate}
                        dateFormat={"DD/MM/YYYY"}
                        helperText={" "}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        component={FormControl.DatePicker}
                        name="searchByToDate"
                        label="To Date"
                        value={searchByToDate}
                        dateFormat={"DD/MM/YYYY"}
                        minDate={
                          searchByFromDate
                            ? convertStringToDate(searchByFromDate)
                            : null
                        }
                        maxDate={
                          searchByFromDate
                            ? createMaxDate(searchByFromDate)
                            : null
                        }
                        helperText={" "}
                      />
                    </Col>
                  </>
                )}
            </Row>
            <Row>
              {searchByType &&
              searchByType === ACCOUNT_SEARCH_TYPES.DATE_SEARCH ? (
                <>
                  <Col md={6}>
                    <Field
                      id="searchByPostcodeFinderInput"
                      component={PostcodeFinderInput}
                      name="searchByPostcode"
                      label="Postcode (Optional)"
                      buttonText="Find Postcode"
                      helperText={" "}
                      onSelectionChange={({ postcode }) => {
                        dispatch(
                          change(
                            ACCOUNT_SEARCH_FORM,
                            "searchByPostcode",
                            postcode
                          )
                        );
                      }}
                      maxLength={8}
                    />
                  </Col>

                  <SubmitButton colSpan={6} />
                </>
              ) : (
                <SubmitButton colSpan={12} />
              )}
            </Row>
          </Step>
        </Col>
      </Card.Stack>
    </form>
  );
};

AccountSearch.propTypes = {
  handleSubmit: PropTypes.func,
  account: PropTypes.string,
  searchByType: PropTypes.string,
  searchByValue: PropTypes.string,
  searchByFromDate: PropTypes.string,
  searchByToDate: PropTypes.string,
  searchByPostcode: PropTypes.string,
  banner: PropTypes.object,
  accounts: PropTypes.array,
  overlay: PropTypes.object,
  dispatch: PropTypes.func,
  fetchParcelReturns: PropTypes.func,
  setAccount: PropTypes.func,
  setSearchFindbyCode: PropTypes.func,
  setFindby: PropTypes.func,
  fetchParcelCodes: PropTypes.func,
  user: PropTypes.object,
  fetchParcelByParcelCode: PropTypes.func,
  fetchRelatedParcels: PropTypes.func,
};

export default compose(
  Banner.withBanner,
  withOverlay,
  connect(state => ({
    accounts: AuthSelectors.getAccounts(state),
    user: AuthSelectors.getAuthUser(state),
  })),
  connect(
    state => {
      const selector = formValueSelector(ACCOUNT_SEARCH_FORM);
      const accounts = formatAccounts(AuthSelectors.getAccounts(state));
      const initialValues = {};

      if (accounts.length === 1) {
        initialValues.account = accounts[0].label;
      } else {
        initialValues.account = "All Accounts";
      }

      return {
        initialValues,
        ...selector(
          state,
          "account",
          "searchByType",
          "searchByValue",
          "searchByFromDate",
          "searchByToDate",
          "searchByPostcode"
        ),
      };
    },
    dispatch => ({
      fetchParcelReturns: params =>
        dispatch(ReturnActions.fetchParcelReturns(params)),
      setAccount: params => dispatch(ReturnActions.setAccount(params)),
      setSearchFindbyCode: params =>
        dispatch(ReturnActions.setSearchFindbyCode(params)),
      setFindby: (code, total) =>
        dispatch(ReturnActions.setFindby(code, total)),
      fetchParcelCodes: params =>
        dispatch(ReturnActions.fetchParcelCodes(params)),
      fetchParcelByParcelCode: parcelCode =>
        dispatch(ParcelViewActions.fetchParcelByParcelCode(parcelCode)),
      fetchRelatedParcels: parcelCode =>
        dispatch(ParcelViewActions.fetchRelatedParcels(parcelCode)),
    })
  ),
  withProps(({ banner, searchByType, overlay }) => ({
    banner,
    searchByType,
    overlay,
  })),
  reduxForm({
    form: ACCOUNT_SEARCH_FORM,
    validate: createValidator(searchSchema),
  })
)(AccountSearch);
