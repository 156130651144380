import PropTypes from "prop-types";

export default function Olympic({ fill = "#DC0032" }) {
  return (
    <svg
      id="Olimpic-1"
      viewBox="0 0 32 32"
      fill={fill}
      width="32px"
      height="32px"
    >
      <path d="M12.256 4.48c1.52 0 2.752 1.232 2.752 2.752s-1.232 2.752-2.752 2.752c-1.52 0-2.752-1.232-2.752-2.752v0c0-1.52 1.232-2.752 2.752-2.752v0zM28.448 29.472l-6.432-10.944c-0.052-0.099-0.105-0.182-0.164-0.262l0.004 0.006c-0.053-0.126-0.107-0.231-0.167-0.332l0.007 0.012q-1.504-2.944-3.168-5.824c2.144-0.704 4.128-0.064 5.696 2.112 1.28 1.76 4.224 0.064 2.944-1.728-3.008-4.192-7.488-5.248-11.968-2.592-0.187 0.102-0.346 0.23-0.478 0.382l-0.002 0.002-0.192 0.096c-0.217 0.045-0.41 0.123-0.584 0.229l0.008-0.005c-2.784 1.632-5.408 1.344-7.328-1.376-0.263-0.385-0.67-0.655-1.142-0.735l-0.010-0.001 0.064-1.952h-1.152l0.064 2.112c-0.584 0.204-0.995 0.75-0.995 1.393 0 0.331 0.109 0.637 0.294 0.883l-0.003-0.004c2.464 3.456 5.92 4.8 9.568 3.68q1.728 2.944 3.296 5.984c-1.645 0.851-3.063 1.83-4.342 2.963l0.022-0.019c-0.336 0.272-0.548 0.684-0.548 1.146 0 0.401 0.16 0.765 0.421 1.030l-0-0c0.146 0.313 0.377 0.565 0.664 0.732l0.008 0.004 5.248 3.2c1.888 1.12 3.584-1.824 1.696-2.944l-3.424-2.048c1.014-0.705 2.177-1.31 3.414-1.759l0.106-0.033c0.208-0.069 0.389-0.167 0.548-0.291l-0.004 0.003 5.088 8.608c1.12 1.888 4.064 0.16 2.944-1.728zM3.296 4.704h3.232v1.376h-3.232zM5.664 4.192c2.272-1.408 0.608-3.616 0.608-3.616 0.003 0.034 0.005 0.073 0.005 0.113 0 0.53-0.33 0.983-0.797 1.164l-0.008 0.003c1.024-0.864-0.512-1.024 0.288-1.856-3.36 1.088-1.664 4.192-1.664 4.192z" />
    </svg>
  );
}

Olympic.propTypes = {
  fill: PropTypes.string,
};
