import PropTypes from "prop-types";

import { ReactComponent as PhotoID } from "../../../../../assets/icons/photo-id.svg";
import styles from "./ParcelStatusInfo.module.scss";

export const ParcelStatusInfo = ({ routeStatus }) => (
  <div className={styles.tracking_status}>
    <PhotoID />
    <span> {routeStatus} </span>
  </div>
);

ParcelStatusInfo.propTypes = {
  routeStatus: PropTypes.string,
};
