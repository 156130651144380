import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient, ums } from "../../apis";

export const UmsActionTypes = createActionTypes("ums", {
  FETCH_PREFERENCES: createAsyncActionTypes("FETCH_PREFERENCES"),
  FETCH_CUSTOMER: createAsyncActionTypes("FETCH_CUSTOMER"),
  FETCH_CUSTOMERS: createAsyncActionTypes("FETCH_CUSTOMERS"),
  FETCH_ADVANCED_CONFIGURATION: createAsyncActionTypes(
    "FETCH_ADVANCED_CONFIGURATION"
  ),
  FETCH_SHIPPING_SETTINGS: createAsyncActionTypes("FETCH_SHIPPING_SETTINGS"),
  FETCH_CUSTOMER_ADDRESS: createAsyncActionTypes("FETCH_CUSTOMER_ADDRESS"),
  FETCH_SECURITY_SETTINGS: createAsyncActionTypes("FETCH_SECURITY_SETTINGS"),
});

export const fetchPreferences = createAsyncAction(
  ums.fetchPreferences,
  UmsActionTypes.FETCH_PREFERENCES
);

export const fetchCustomer = createAsyncAction(
  () => apisClient.ums.fetchCustomer(),
  UmsActionTypes.FETCH_CUSTOMER
);

export const fetchCustomers = createAsyncAction(
  () => apisClient.ums.fetchCustomers(),
  UmsActionTypes.FETCH_CUSTOMERS
);

export const fetchAdvanceConfiguration = createAsyncAction(
  ums.fetchAdvanceConfiguration,
  UmsActionTypes.FETCH_ADVANCED_CONFIGURATION
);

export const fetchShippingSettings = createAsyncAction(
  ums.fetchShippingSettings,
  UmsActionTypes.FETCH_SHIPPING_SETTINGS
);

export const fetchCustomerAddress = createAsyncAction(
  ums.fetchCustomerAddress,
  UmsActionTypes.FETCH_CUSTOMER_ADDRESS
);

export const fetchSecuritySettings = createAsyncAction(
  ums.fetchSecuritySettings,
  UmsActionTypes.FETCH_SECURITY_SETTINGS
);

export const fetchInitialData = () => dispatch =>
  Promise.all([
    dispatch(fetchPreferences()),
    dispatch(fetchCustomer()),
    dispatch(fetchCustomers()),
    dispatch(fetchAdvanceConfiguration()),
    dispatch(fetchShippingSettings()),
    dispatch(fetchCustomerAddress()),
    dispatch(fetchSecuritySettings()),
  ]);
