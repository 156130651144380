import PropTypes from "prop-types";
import { Field } from "redux-form";

import PostcodeFinderInput from "../../../../../components/PostcodeFinderInput/PostcodeFinderInput";
import { getAddressFields } from "../../../../../utils/forms";
import { joinStringsWithComma } from "../../../../../utils/string";

export const PostcodeInput = ({
  formSection,
  updateFormField,
  withAutocomplete,
}) => {
  return (
    <Field
      id={`${formSection}Postcode`}
      component={PostcodeFinderInput}
      name={`${formSection}Postcode`}
      label="Postal / Zip Code"
      buttonText="Find Postcode"
      helperText="Destination"
      onSelectionChange={values => {
        const addressValues = getAddressFields(formSection, values);
        updateFormField({ returnFromCountry: "GB", ...addressValues });
      }}
      textTransform="uppercase"
      labelKey={option => option.postcode}
      optionLabelMapper={option =>
        joinStringsWithComma([
          option.organisation,
          option.property,
          option.street,
          option.town,
        ])
      }
      withAutocomplete={withAutocomplete}
      showFindButton={withAutocomplete}
      maxLength={8}
      required
      pageSize={50}
    />
  );
};

PostcodeInput.propTypes = {
  formSection: PropTypes.string,
  updateFormField: PropTypes.func,
  withAutocomplete: PropTypes.bool,
};
