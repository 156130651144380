import PropTypes from "prop-types";
import { compose } from "redux";

import { AuthUtil, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { Legend, Main } from "@dpdgroupuk/mydpd-ui";

import AccountSearch from "./components/AccountSearch/AccountSearch";

const ReturnSearch = ({ authUser }) => {
  return (
    <Main.Body>
      <Legend
        leftMessage="Shop Returns Search"
        rightMessage={`${AuthUtil.getDisplayName(authUser?.user)} (Account: ${
          authUser?.user?.account
        })`}
      />
      <AccountSearch />
    </Main.Body>
  );
};

ReturnSearch.propTypes = {
  authUser: PropTypes.object,
};

export default compose(withAppUserPreferences)(ReturnSearch);
