import PropTypes from "prop-types";
import { Container, Row } from "react-bootstrap";

import { FallBackImage, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import styles from "./ReturnStatus.module.scss";

export const ReturnStatus = ({ parcel }) => {
  const { logoImg } = useMyDpdTheme();

  return (
    <Container className={styles.container}>
      <Row>
        <div className="mydpd-card-title mydpd-card-step-title card-title h5">
          <p className="mydpd-card-text mydpd-card-step-text card-text">
            {parcel.parcelStatus}
          </p>
        </div>

        <div className={styles.customerLogoContainer}>
          <FallBackImage
            id="Fall-back-image-1"
            className={styles.customerLogo}
            src={parcel.customerLogoUrl || logoImg}
            fallBackSrc={logoImg}
            alt="Customer Logo Image"
          />
        </div>
      </Row>
    </Container>
  );
};

ReturnStatus.propTypes = {
  parcel: PropTypes.object,
};
