import { useEffect, useState } from "react";

import { isEmpty, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { lifecycle, withProps } from "recompose";
import { compose } from "redux";
import {
  change,
  Field,
  formValueSelector,
  propTypes,
  reduxForm,
  untouch,
} from "redux-form";

import {
  Banner,
  Button,
  FormControl,
  Step,
  withOverlay,
} from "@dpdgroupuk/mydpd-ui";

import { ReactComponent as HideFilters } from "../../../../../assets/icons/hide-filters.svg";
import { ReactComponent as ShowFilters } from "../../../../../assets/icons/show-filters.svg";
import { AccountDropdown } from "../../../../../components/AccountDropdown/AccountDropdown";
import PostcodeFinderInput from "../../../../../components/PostcodeFinderInput/PostcodeFinderInput";
import {
  COLLECTION_COUNTRIES_DROPDOWN_OPTIONS,
  DELIVERED_FILTER_DROPDOWN_OPTIONS,
  DESTINATION_FILTER_DROPDOWN_OPTIONS,
  DIRECTION_FILTER_DROPDOWN_OPTIONS,
  FILTER_FIELD_DEFAULT_VALUES,
  FILTER_FORM,
  FILTER_FORM_FIELDS,
  OFD_TODAY_FILTER_DROPDOWN_OPTIONS,
  SORT_BY_FILTER,
} from "../../../../../constants/forms";
import {
  AuthSelectors,
  ReturnActions,
  SearchActions,
} from "../../../../../redux";
import {
  createFilterFormDropdownValues,
  createFilterFormInitalValues,
  removeUnusedFilters,
} from "../../../../../utils/forms";
import {
  getFilters,
  getSearchQuery,
  stringifyQuery,
} from "../../../../../utils/query";
import styles from "./FilterResults.module.scss";

const FilterResults = ({
  dispatch,
  productCode,
  deliveryCountryCode,
  accountCode,
  serviceCode,
  postcode,
  collectionCountryCode,
  deliveryDepot,
  destinationTypeCode,
  range,
  ofdToday,
  undelivered,
  sortBy,
  directionCode,
  fetchFilterReturns,
  handleSubmit,
  fetchParcelCodes,
  fetchParcelByParcelCode,
  fetchParcelReturns,
  banner,
  overlay,
  pagination,
  setError,
  fetchReturnsDashboardFindbyCode,
  ...props
}) => {
  const { filters } = useSelector(state => state.ui.search);
  const accounts = useSelector(state => AuthSelectors.getAccounts(state));
  const { findByCode, searchFindByCode } = useSelector(state => state.returns);
  const { user } = useSelector(state => state.app.auth);
  const [showFilters, setShowFilters] = useState(false);
  const [resettingPagination, setresettingPagination] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    banner.removeAll();
    const fetchParcelData = async () => {
      overlay.show();

      try {
        const { parcelCodes } = await fetchParcelCodes({
          findByCode,
          startPos: pagination.startPos,
          endPos: pagination.endPos,
        });
        await fetchParcelByParcelCode({
          parcelCode: parcelCodes,
        });
      } catch (error) {
        setError(true);
        overlay.hide();
      }

      overlay.hide();
    };

    if (!resettingPagination) {
      fetchParcelData();
    }
  }, [pagination.startPos, pagination.endPos, findByCode]);

  const resetForm = async () => {
    const filters = getFilters(location);
    if (isEmpty(filters)) {
      resetFields();
      banner.removeAll();
      return;
    }

    const query = getSearchQuery(location);
    const fetchParcelReturnsReq = query;

    if ("pot" in fetchParcelReturnsReq) {
      const request = {
        accountNumber: query.account,
        pot: query.pot,
        businessUnit: query.businessUnit,
      };

      if (request.pot !== "atShop") {
        request.estimatedDeliveryDate = query.deliveryDate;
      }

      if (request.pot === "expectedAfter") {
        const expectedAfterDates = query.deliveryDate;
        const dates = expectedAfterDates.slice(1, expectedAfterDates.length);
        request.estimatedDeliveryDate = dates;
      }

      const { findByCode } = await fetchReturnsDashboardFindbyCode(request);
      if (!isEmpty(findByCode)) {
        const { parcelCodes } = await fetchParcelCodes({
          findByCode,
          startPos: 1,
          endPos: 25,
        });

        await fetchParcelByParcelCode({
          parcelCode: parcelCodes,
        });
      }
    } else {
      await fetchParcelReturns(omitBy(fetchParcelReturnsReq, isNil));
    }

    const queryParams = stringifyQuery({ ...query });
    history.replace({ search: queryParams.toString() });
    resetFields();
  };

  const handleFilterSubmit = async () => {
    overlay.show();

    let sortFilters = { sortBy, sortOrder: undefined };
    if (sortFilters.sortBy) {
      const [sortBy, sortOrder] = sortFilters.sortBy.split("-");
      sortFilters = { sortBy, sortOrder };
    }

    let accountNumbers;
    if (accountCode === "all" || accountCode === "All Accounts") {
      accountNumbers = null;
    } else {
      accountNumbers = accountCode;
    }

    const filters = removeUnusedFilters({
      accountCode: accountNumbers,
      productCode,
      serviceCode,
      collectionCountryCode,
      deliveryCountryCode,
      postcode,
      range: range.split("- ")[1], // get parcel range end
      undelivered,
      sortBy: sortFilters.sortBy,
      sortOrder: sortFilters.sortOrder,
      destinationTypeCode,
      searchDays: 1,
      deliveryDepot,
      ofdToday,
      directionCode,
    });

    try {
      const { findbyCode, findByTotal } = await fetchFilterReturns({
        ...filters,
        businessUnit: user.businessId,
        findByCode: searchFindByCode,
      });

      if (findByTotal > 0) {
        setresettingPagination(true);
        pagination.reset(1, 25);
        pagination.setPage(1);

        setresettingPagination(false);
        dispatch(ReturnActions.setFindby(findbyCode, findByTotal));

        const query = getSearchQuery(location);
        history.push({
          search: stringifyQuery({
            ...query,
            ...filters,
          }),
        });
      }
    } catch (error) {
      overlay.hide();
      banner.removeAll();
      banner.showByType("alert", {
        message: "No results found, please reset filters",
        closable: true,
        showIcon: true,
        actions: [],
      });
    }
  };

  const resetFields = () => {
    Object.keys(FILTER_FIELD_DEFAULT_VALUES).forEach(fieldKey => {
      dispatch(
        change(FILTER_FORM, fieldKey, FILTER_FIELD_DEFAULT_VALUES[fieldKey])
      );
      dispatch(untouch(FILTER_FORM, fieldKey));
    });

    props.initialize(FILTER_FIELD_DEFAULT_VALUES);
  };

  return (
    <form
      onSubmit={handleSubmit(handleFilterSubmit)}
      className={styles.formContainer}
    >
      <Col md={12} className="parcel-filters">
        <Step
          title={
            <div className={styles.titleContainer}>
              <span>Filter Results</span>
              {!showFilters ? (
                <ShowFilters
                  className={styles.addFilterButton}
                  onClick={() => setShowFilters(true)}
                />
              ) : (
                <HideFilters
                  className={styles.addFilterButton}
                  onClick={() => setShowFilters(false)}
                />
              )}
            </div>
          }
        >
          {showFilters && (
            <>
              <Row className={styles.filterRow}>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.PRODUCT}
                    label="Product"
                    values={createFilterFormDropdownValues(
                      "products",
                      filters.product
                    )}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.DELIVERY_COUNTRY}
                    label="Delivery Country"
                    values={createFilterFormDropdownValues(
                      "countries",
                      filters.country
                    )}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <AccountDropdown
                    accounts={["All Accounts", ...accounts]}
                    name={FILTER_FORM_FIELDS.ACCOUNT_CODE}
                    useContainer={false}
                  />
                </Col>
              </Row>

              <Row className={styles.filterRow}>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.SERVICE}
                    label="Service"
                    values={createFilterFormDropdownValues(
                      "services",
                      filters.service
                    )}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4} className="postcode-filter">
                  <Field
                    id="filterPostcodeFinderInput"
                    component={PostcodeFinderInput}
                    name={FILTER_FORM_FIELDS.POSTCODE}
                    label="Postcode"
                    buttonText="Find Postcode"
                    onSelectionChange={({ postcode }) => {
                      dispatch(change(FILTER_FORM, "postcode", postcode));
                    }}
                    maxLength={8}
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.COLLECT_COUNTRY}
                    label="Collection Countries"
                    values={COLLECTION_COUNTRIES_DROPDOWN_OPTIONS}
                    textTransform="uppercase"
                  />
                </Col>
              </Row>

              <Row className={styles.filterRow}>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.DELIVERY_DEPOT}
                    label="Delivery Depot"
                    values={createFilterFormDropdownValues(
                      "depots",
                      filters.depot
                    )}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.DESTINATION}
                    label="Destination"
                    values={DESTINATION_FILTER_DROPDOWN_OPTIONS}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.RANGE}
                    label="Range"
                    values={createFilterFormDropdownValues(
                      "ranges",
                      filters.customersRanges
                    )}
                    textTransform="uppercase"
                  />
                </Col>
              </Row>

              <Row className={styles.filterRow}>
                <Col md={2}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.OFD_TODAY}
                    label="OFD Today"
                    values={OFD_TODAY_FILTER_DROPDOWN_OPTIONS}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={2}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.DELIVERED}
                    label="Delivered"
                    values={DELIVERED_FILTER_DROPDOWN_OPTIONS}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.SORT_BY}
                    label="Sort By"
                    values={SORT_BY_FILTER}
                    textTransform="uppercase"
                  />
                </Col>
                <Col md={4}>
                  <Field
                    component={FormControl.Dropdown}
                    name={FILTER_FORM_FIELDS.DIRECTION}
                    label="Direction"
                    values={DIRECTION_FILTER_DROPDOWN_OPTIONS}
                    textTransform="uppercase"
                  />
                </Col>
              </Row>

              <div className={styles.buttonsContainer}>
                <Button
                  className="button"
                  variant="secondary"
                  onClick={resetForm}
                >
                  Reset
                </Button>

                <Button className="ml-3 button" type="submit">
                  Apply
                </Button>
              </div>
            </>
          )}
        </Step>
      </Col>
    </form>
  );
};

FilterResults.propTypes = {
  ...propTypes,
  product: PropTypes.string,
  deliveryCountryCode: PropTypes.string,
  accountCode: PropTypes.string,
  serviceCode: PropTypes.string,
  postcode: PropTypes.string,
  collectionCountryCode: PropTypes.string,
  deliveryDepot: PropTypes.string,
  destinationTypeCode: PropTypes.string,
  range: PropTypes.string,
  ofdToday: PropTypes.string,
  undelivered: PropTypes.string,
  sortBy: PropTypes.string,
  directionCode: PropTypes.string,
  dispatch: PropTypes.func,
  fetchFilterReturns: PropTypes.func,
  handleSubmit: PropTypes.func,
  fetchParcelCodes: PropTypes.func,
  fetchParcelByParcelCode: PropTypes.func,
  fetchParcelReturns: PropTypes.func,
  banner: PropTypes.object,
  overlay: PropTypes.object,
  pagination: PropTypes.object,
  setError: PropTypes.func,
  fetchReturnsDashboardFindbyCode: PropTypes.func,
};

export default compose(
  Banner.withBanner,
  withOverlay,
  connect(
    state => {
      const selector = formValueSelector(FILTER_FORM);
      const { filterValues } = state.ui.search;
      const initialValues = createFilterFormInitalValues(filterValues);

      return {
        initialValues,
        ...selector(state, ...Object.values(FILTER_FORM_FIELDS)),
      };
    },
    dispatch => ({
      fetchFilterReturns: params =>
        dispatch(SearchActions.fetchFilterReturns(params)),
      fetchParcelCodes: params =>
        dispatch(ReturnActions.fetchParcelCodes(params)),
      fetchParcelByParcelCode: params =>
        dispatch(ReturnActions.fetchParcelByParcelCode(params)),
      fetchParcelReturns: params =>
        dispatch(ReturnActions.fetchParcelReturns(params)),
      fetchReturnsDashboardFindbyCode: params =>
        dispatch(ReturnActions.fetchReturnsDashboardFindbyCode(params)),
      resetSearchResults: () => dispatch(SearchActions.resetSearchResults()),
    })
  ),
  withProps(({ banner, overlay }) => ({
    banner,
    overlay,
  })),
  reduxForm({
    form: FILTER_FORM,
    enableReinitialize: true,
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.resetSearchResults();
    },
  })
)(FilterResults);
