export const Plug = props => (
  <svg viewBox="0 0 81 50" {...props} id="plug">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="ID-needed-and-Electric---Desktop---Dark"
        transform="translate(-22.000000, -503.000000)"
        fill="#1D8C23"
      >
        <g
          id="Group-3"
          transform="translate(44.000000, 528.000000) scale(1, -1) translate(-44.000000, -528.000000) translate(-37.000000, 503.000000)"
        >
          <path
            d="M59,44.1964286 L95.4945055,44.1964286 C97.6879646,44.1964286 98.5817376,43.0697972 98.5817376,40.7645211 C98.5817376,38.4565831 96.799154,36.5793537 94.6056949,36.5793537 L79.1288555,36.5793537 C72.8722947,36.1869776 67.9757635,30.9348879 67.9757635,24.6243944 C67.9757635,18.0226801 73.3160827,12.6481393 79.8810654,12.6481393 L104.123779,12.6481393 L104.123779,8.50822552 C104.123779,3.8156844 107.914025,0 112.575922,0 L122.527177,0 C125.549288,0 128.004985,2.47617526 128.004985,5.51935261 L128.004985,7.19799819 L137.554919,7.19799819 C138.905394,7.19799819 140,8.30005856 140,9.65553958 C140,11.014215 138.905394,12.115743 137.554919,12.115743 L128.004985,12.115743 L128.004985,18.3245488 L137.554919,18.3245488 C138.905394,18.3245488 140,19.4292711 140,20.7874142 C140,22.1402332 138.905394,23.2417612 137.554919,23.2417612 L128.004985,23.2417612 L128.004985,24.9214715 C128.004985,27.9454826 125.565213,30.403024 122.558496,30.403024 L112.575922,30.403024 C107.914025,30.403024 104.123779,26.5868072 104.123779,21.8969281 L104.123779,17.714955 L79.8810654,17.714955 C76.0881648,17.714955 73.0050064,20.8193579 73.0050064,24.6297184 C73.0050064,28.1701539 75.7399293,31.191503 79.1739775,31.5130703 L94.6056949,31.5130703 C99.5760137,31.5130703 103.618943,35.6572433 103.618943,40.7565352 C103.618943,45.8526327 99.5760137,50 94.6056949,50 L59,49.5535714 L59,44.1964286 Z M109.011816,8.95465409 L109.011816,22.3433566 C109.011816,24.3196118 110.611789,25.9290458 112.575922,25.9290458 L122.558496,25.9290458 C122.864795,25.9290458 123.116947,25.6750928 123.116947,25.3679001 L123.116947,5.96578118 C123.116947,5.63622786 122.851524,5.36630293 122.527177,5.36630293 L112.575922,5.36630293 C110.611789,5.36630293 109.011816,6.97839897 109.011816,8.95465409"
            id="Fill-1"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default Plug;
