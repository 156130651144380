import { useMemo, useState } from "react";

import PropTypes from "prop-types";

import { ImageView } from "@dpdgroupuk/mydpd-ui";

import photoImg from "../../../../../../assets/icons/photo.svg";
import { getImageUrl } from "../../../../../../helpers/ParcelView";

export const ImageViewCell = ({ row }) => {
  const [showImage, setShowImage] = useState(false);

  const images = useMemo(() => {
    return row.images.map(({ imageKey, imageType, imageCaption }) => ({
      src: getImageUrl(imageKey, imageType),
      title: imageCaption,
    }));
  }, [row]);

  return (
    <>
      {images.length > 0 && (
        <img
          id="Image-view-cell-1"
          className="cursor-pointer"
          alt="img"
          src={photoImg}
          onClick={() => {
            setShowImage(true);
          }}
        />
      )}
      <ImageView
        images={images}
        show={showImage}
        carouselProps={{
          indicators: false,
        }}
        onClose={() => {
          setShowImage(false);
        }}
      />
    </>
  );
};

ImageViewCell.propTypes = {
  row: PropTypes.object,
};
