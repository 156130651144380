import PropTypes from "prop-types";

export default function LocalEvent({ fill = "#DC0032" }) {
  return (
    <svg
      id="Local-event-1"
      viewBox="0 0 32 32"
      fill={fill}
      width="32px"
      height="32px"
    >
      <path d="M16 14.368l4.128 2.176-0.768-4.608 3.328-3.264-4.608-0.672-2.080-4.192-2.048 4.192-4.64 0.672 3.36 3.264-0.8 4.608zM15.168 9.728l0.832-1.664 0.832 1.664 1.824 0.256-1.312 1.28 0.288 1.824-1.632-0.864-1.632 0.864 0.32-1.824-1.344-1.28zM24.288 22.976c-0.864-0.64-1.984-1.184-3.296-1.536l3.008-4.576c1.433-1.758 2.302-4.025 2.304-6.495v-0.001c0-5.728-4.608-10.368-10.304-10.368s-10.304 4.64-10.304 10.368c-0 0.018-0 0.040-0 0.062 0 2.043 0.596 3.947 1.625 5.547l-0.025-0.041c0.064 0.096 0.128 0.192 0.192 0.288l3.488 5.216c-1.28 0.384-2.4 0.896-3.264 1.536-1.312 0.96-2.016 2.144-2.016 3.392 0 1.632 1.152 3.104 3.264 4.16 1.888 0.96 4.384 1.472 7.040 1.472s5.152-0.512 7.040-1.472c2.112-1.056 3.264-2.528 3.264-4.16 0-1.248-0.704-2.432-2.016-3.392zM9.088 15.2v0l-0.032-0.032-0.16-0.256c-0.839-1.284-1.34-2.855-1.344-4.543v-0.001c0-4.672 3.808-8.48 8.448-8.48s8.448 3.808 8.448 8.48c-0.005 2.036-0.729 3.902-1.931 5.358l0.011-0.014-0.032 0.032-6.496 9.856zM16 30.112c-4.96 0-8.448-1.952-8.448-3.744 0-1.312 1.824-2.624 4.512-3.296l3.936 5.952 3.904-5.952c2.688 0.672 4.544 1.984 4.544 3.296 0 1.792-3.488 3.744-8.448 3.744z" />
    </svg>
  );
}

LocalEvent.propTypes = {
  fill: PropTypes.string,
};
