import { useState } from "react";

import PropTypes from "prop-types";

import mapImg from "../../../../../../assets/icons/map.svg";
import { getEventLocationMapUrl } from "../../../../../../helpers/ParcelView";
import { MapViewModal } from "../../MapViewModal/MapViewModal";

export const MapViewCell = ({ row }) => {
  const [showMap, setShowMap] = useState(false);
  const { latitude, longitude } = row;

  return (
    <>
      {latitude && longitude && (
        <img
          id="Map-view-cell-1"
          alt="map"
          className="cursor-pointer"
          src={mapImg}
          onClick={() => {
            setShowMap(true);
          }}
        />
      )}
      <MapViewModal
        show={showMap}
        src={getEventLocationMapUrl({ longitude, latitude })}
        onClose={() => {
          setShowMap(false);
        }}
      />
    </>
  );
};

MapViewCell.propTypes = {
  row: PropTypes.object,
};
