import ActionTypes from "./actionTypes";

export const initialState = {
  pickupCountries: {},
  profiles: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PROFILES.SUCCESS: {
      return {
        ...state,
        profiles: action.payload,
      };
    }
    case ActionTypes.GET_PROFILES.FAILURE: {
      return {
        ...state,
        profiles: initialState.profiles,
      };
    }
    case ActionTypes.GET_PICKUP_COUNTRIES.SUCCESS: {
      return {
        ...state,
        pickupCountries: action.payload,
      };
    }
    case ActionTypes.GET_PICKUP_COUNTRIES.FAILURE: {
      return {
        ...state,
        pickupCountries: initialState.pickupCountries,
      };
    }
    case ActionTypes.RESET_CREATE_RETURN: {
      return initialState;
    }
    default:
      return state;
  }
};
