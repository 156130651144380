import { useEffect } from "react";

import PropTypes from "prop-types";

import { Banner, Main } from "@dpdgroupuk/mydpd-ui";

export const DashboardRedirect = ({ history }) => {
  const { banner } = Banner.useBanner();

  useEffect(() => {
    banner.showByType("warning", {
      message: "The dashboard is not enabled for this user",
      closable: false,
      actions: [
        {
          text: "Go to Search",
          handleClick: () => history.push("/search"),
        },
      ],
      showIcon: true,
    });

    return () => {
      banner.hideAll();
    };
  }, []);

  return <Main body></Main>;
};

DashboardRedirect.propTypes = {
  history: PropTypes.object,
};
