import { add, differenceInDays, format, parse, parseISO } from "date-fns";
import { addWeekDays } from "moment-business";
import moment from "moment-timezone";

const formatMomentDate = (date, outputFormat, inputFormat) =>
  moment(date, inputFormat).format(outputFormat);

export const formatTime = date => formatMomentDate(date, "H:mm");

export const convertStringToDate = date => {
  const dateParts = date.split("/");
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};

export const createMaxDate = fromDate => {
  const dateObject = convertStringToDate(fromDate);
  return add(dateObject, { days: 6 });
};

export const formatDate = date => {
  return format(parseDate(date), "yyyy-MM-dd");
};

export const formatDDMMMYYYY = date => {
  return date ? moment(date, "DD/MM/YYYY").format("DD MMM YYYY") : "";
};

export const formatYYYYMMDDDD = date => {
  return date ? moment(date, "YYYY-MM-DD").format("DD MMM YYYY") : "";
};

export const formatDashboardStatDate = date => {
  const dateObj = parseISO(date, "dd/MM/yyyy");
  const formattedDate = format(dateObj, "dd/MM/yyyy");
  return formattedDate;
};

export const parseDate = date => {
  const currentDateFormat = "dd/MM/yyyy";
  return parse(date, currentDateFormat, new Date());
};

export const diffInDays = (fromDate, ToDate) => {
  const formattedSearchByFromDate = parseDate(fromDate);
  const formattedSearchByToDate = parseDate(ToDate);
  return (
    differenceInDays(formattedSearchByToDate, formattedSearchByFromDate) + 1
  );
};

export const calculateExpectedReturnDates = () => {
  let currentDate = moment(new Date());

  const expectedReturnDates = [currentDate.format("YYYY-MM-DD")];
  for (let i = 1; i <= 6; i++) {
    const date = addWeekDays(currentDate, 1).format("YYYY-MM-DD");
    expectedReturnDates.push(date);
    currentDate = moment(date);
  }

  return expectedReturnDates;
};

export const getMilliseconds = date => {
  return moment(date).valueOf();
};

export const chooseSmallerDate = (date1, date2) =>
  getMilliseconds(date1) > getMilliseconds(date2) ? date2 : date1;

export const formatInternalTime = seconds => {
  const time = moment().startOf("day").add(seconds, "s");
  return formatMomentDate(time, "YYYY-MM-DD HH:mm:ss");
};

export const calculatePositionInPeriod = (date, periodStart, periodEnd) => {
  const start = getMilliseconds(periodStart);
  const end = getMilliseconds(periodEnd);
  const value = getMilliseconds(date);
  return Math.round(((value - start) / (end - start)) * 100);
};

export const getUKCurrentMomentTime = () => moment().tz("Europe/London");

export const getCurrentDateTime = () => {
  const ukTime = getUKCurrentMomentTime();
  return formatMomentDate(ukTime, "YYYY-MM-DD HH:mm:ss");
};
