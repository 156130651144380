import Joi from "joi";

import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";

import {
  DECIMAL_NUMBER,
  EMAIL_ADDRESS,
  TELEPHONE_NUMBER,
  WHOLE_NUMBER,
} from "./regex";

const ORG_OR_CONTACT = "Organisation or Contact Name";
const $_MUST_BE_ENTERED = "{0} must be entered";
const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);

export const account = Joi.string().label("Account").required();

export const searchByType = Joi.string().label("Search By").required();

export const parcelNumber = Joi.string()
  .length(14)
  .regex(WHOLE_NUMBER, "Parcel Number")
  .label("Parcel Number")
  .error(
    joiExtension.messages({
      "string.regex.name": "Parcel Number value must be numeric",
    })
  )
  .required();

export const fromDate = Joi.string().label("From Date").required();

export const toDate = Joi.string().label("To Date").required();

export const shortName = Joi.string().label("Short Name").max(45);

export const country = Joi.string().label("Country").required();

export const postcode = Joi.string().max(8).label("Postcode");

export const organisation = Joi.string().label("Organisation").max(35);

export const address1 = Joi.string().label("Address1").max(35).required();

export const address2 = Joi.string().label("Address2").max(35);

export const city = Joi.string().label("City").max(35).required();

export const county = Joi.string().label("County").max(35);

export const name = Joi.string().label("Name").max(35);
export const returnReference = Joi.string().max(25).label("Return Reference");

export const telephone = Joi.string()
  .label("Telephone Number")
  .regex(TELEPHONE_NUMBER, "returnFromTelephone")
  .max(15);

export const email = Joi.string()
  .label("Email Address")
  .regex(EMAIL_ADDRESS, "returnFromEmail")
  .max(100);

export const totalWeight = Joi.string()
  .label("Total Weight")
  .regex(DECIMAL_NUMBER, "returnFromTotalWeight")
  .required()
  .error(
    joiExtension.messages({
      "string.regex.name": "Total Weight must be numeric",
    })
  );

export const numberOfParcels = Joi.number()
  .label("Number Of Parcels")
  .error(
    joiExtension.messages({
      "number.min": "Number of Parcels must be a maximum of 3",
      "number.max": "Number of Parcels must be a maximum of 3",
    })
  )
  .min(1)
  .max(3)
  .integer()
  .required();

export const deliveryContactName = Joi.string()
  .label("Contact Name")
  .max(35)
  .error(
    joiExtension.messages({
      "any.required": formatMessage($_MUST_BE_ENTERED, ORG_OR_CONTACT),
    })
  );

export const deliveryOrganisation = Joi.string()
  .label("Organisation")
  .max(35)
  .error(
    joiExtension.messages({
      "any.required": formatMessage($_MUST_BE_ENTERED, ORG_OR_CONTACT),
    })
  );
