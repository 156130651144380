import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";

import { Tile } from "@dpdgroupuk/mydpd-ui";

import StatTile from "../StatTile/StatTile";

export const DashboardStats = ({ stats, account }) => (
  <Row>
    <Tile.Stack>
      {stats.map(({ title, amount, id, date }) => (
        <StatTile
          key={id}
          title={title}
          date={date}
          headerTitle={amount}
          disabled={!amount}
          account={account}
          pot={id}
        />
      ))}
    </Tile.Stack>
  </Row>
);

DashboardStats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      amount: PropTypes.number,
      id: PropTypes.number,
    })
  ),
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
