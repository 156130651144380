import { get, isEmpty, isEqual, truncate } from "lodash";

import {
  FILTER_FIELD_DEFAULT_VALUES,
  FILTER_FORM_FIELDS,
  REQUIRED_ADDRESS_BOOK_FIELDS,
  RETURN_FROM_REVIEW_CONTACT_DETAILS,
  RETURN_FROM_REVIEW_DETAILS,
  RETURN_TO_REVIEW_DETAILS,
} from "../constants/forms";

export const createFilterFormDropdownValues = (filterType, filters) => {
  const filterArr = [{ label: `All ${filterType}`, value: "all" }];

  switch (filterType) {
    case "ranges":
      if (isEmpty(filters)) {
        filterArr[0] = { label: "None", value: "none" };
      } else {
        filters.forEach(filter => {
          filterArr.push({ label: filter, value: filter });
        });
      }
      break;
    case "depots":
      for (const filter of filters) {
        const { depotDescription, depotCode } = filter;
        filterArr.push({ label: depotDescription, value: depotCode });
      }
      break;
    default:
      for (const filter of filters) {
        const { name, id } = filter;
        filterArr.push({ label: name, value: id });
      }
      break;
  }

  // Sort filters in alphabetical order while keeping All ${filterType} or Not specified as first select option
  return filterArr.sort(({ label: a }, { label: b }) =>
    a === `All ${filterType}` || a === "Not specified"
      ? -1
      : b === `All ${filterType}` || b === "Not specified"
      ? 1
      : a.localeCompare(b)
  );
};

export const removeUnusedFilters = filters => {
  Object.keys(filters).forEach(key =>
    isEmpty(filters[key]) || filters[key] === "all" || filters[key] === "na"
      ? delete filters[key]
      : {}
  );

  return filters;
};

export const createDashboardStatEnum = (title, amount, id, date = null) => ({
  title,
  amount,
  id,
  date,
});

export const createFilterFormInitalValues = values => {
  const initialValues = {};

  Object.values(FILTER_FORM_FIELDS).forEach(filter => {
    if (Object.keys(values).indexOf(filter) > -1) {
      initialValues[filter] = values[filter];
    } else {
      initialValues[filter] = FILTER_FIELD_DEFAULT_VALUES[filter];
    }
  });

  return initialValues;
};

export const getFormErrors = (formSection, errors) => {
  const formErrors = {};

  if (!errors) {
    return formErrors;
  }

  for (const [key, value] of Object.entries(errors)) {
    if (key.startsWith(formSection)) {
      formErrors[key] = value;
    }
  }

  return formErrors;
};

export const getAddressBookFormErrors = (formSection, errors) => {
  const formErrors = {};

  if (!errors) {
    return formErrors;
  }

  for (const [key, value] of Object.entries(errors)) {
    const field = key.replace(formSection, "");
    if (
      key.startsWith(formSection) &&
      REQUIRED_ADDRESS_BOOK_FIELDS.includes(field)
    ) {
      formErrors[key] = value;
    }
  }

  return formErrors;
};

export const mapReturnReviewDetails = (formValues, selectedCountry) => {
  const returnFromReviewDetails = [];
  RETURN_FROM_REVIEW_DETAILS.map(({ inputName }) =>
    returnFromReviewDetails.push({
      inputName,
      value: get(formValues, inputName),
    })
  );
  returnFromReviewDetails.push({ value: selectedCountry.countryName });

  const returnFromReviewContactDetails = [];
  RETURN_FROM_REVIEW_CONTACT_DETAILS.map(({ inputName, title }) =>
    returnFromReviewContactDetails.push({
      inputName,
      value: get(formValues, inputName),
      title,
    })
  );

  const returnToReviewDetails = [];
  RETURN_TO_REVIEW_DETAILS.map(({ inputName }) =>
    returnToReviewDetails.push({
      inputName,
      value: get(formValues, inputName),
    })
  );
  returnToReviewDetails.push({ value: selectedCountry.countryName });

  return {
    returnFromReviewDetails,
    returnFromReviewContactDetails,
    returnToReviewDetails,
  };
};

export const transformAddressValues = ({ street, locality, property }) => {
  let addressLine1;
  let addressLine2;

  if (property) {
    addressLine1 = `${property} ${street}`;
  } else {
    addressLine1 = street;
  }

  if (addressLine1 && addressLine1.length > 35) {
    addressLine1 = property;
    addressLine2 = `${street}, ${locality}`;
  } else {
    addressLine2 = locality;
  }

  if (addressLine2 && addressLine2.length > 35) {
    addressLine2 = truncate(addressLine2, {
      length: 35,
      separator: " ",
      omission: "",
    });
  }

  return {
    addressLine1,
    addressLine2,
  };
};

export const getAddressFields = (formSection, address) => {
  if (isEmpty(address)) return;

  const { addressLine1, addressLine2 } = transformAddressValues(address);

  return {
    [`${formSection}Postcode`]: address?.postcode,
    [`${formSection}Organisation`]: truncate(address?.organisation, {
      length: 35,
      separator: " ",
      omission: "",
    }),
    [`${formSection}Address1`]: addressLine1,
    [`${formSection}Address2`]: addressLine2,
    [`${formSection}City`]: address?.town,
    [`${formSection}County`]: address?.county,
  };
};

export const getAddressBookFieldValues = (formSection, values) => {
  const { shortName, address, contactDetails, notificationDetails } = values;

  const addressValues = getAddressFields(formSection, address);
  const obj = { ...addressValues };

  obj[`${formSection}ShortName`] = shortName;
  obj.returnFromCountry = address?.countryCode;

  if (formSection === "returnFrom") {
    obj[`${formSection}Name`] = contactDetails?.contactName;
    obj[`${formSection}Telephone`] =
      contactDetails?.telephone || notificationDetails?.mobile;
    obj[`${formSection}Email`] = notificationDetails?.email;
  }

  if (formSection === "returnTo") {
    obj[`${formSection}ContactName`] = contactDetails?.contactName;
  }

  return obj;
};

export const showAddressBookButton = (
  errors,
  selectedAddress,
  shortName,
  currentValues,
  addressbookValues
) => {
  if (!errors || !selectedAddress || !shortName) {
    return false;
  }

  let canEdit = false;
  if (selectedAddress.read && selectedAddress.edit) {
    canEdit = selectedAddress.read && selectedAddress.edit;
  } else if (!selectedAddress.readOnly) {
    canEdit = true;
  }

  if (canEdit && isEmpty(errors)) {
    const isSameAddressBook = isEqual(currentValues, addressbookValues);

    if (!isSameAddressBook) {
      return true;
    }
    return false;
  }

  return isEmpty(errors) && canEdit;
};

export const getReturnFormValues = (formSection, values) => {
  const addressValues = getAddressFields(formSection, values);
  const obj = { ...addressValues };

  obj[`${formSection}ShortName`] = values?.[`${formSection}ShortName`];
  obj[`${formSection}Country`] = values?.[`${formSection}Country`];

  if (formSection === "returnFrom") {
    obj.returnFromName = values?.returnFromName;
    obj.returnFromTelephone = values?.returnFromTelephone;
    obj.returnFromEmail = values?.returnFromEmail;
    obj.returnFromName = values?.returnFromName;
    obj.returnFromNumberOfParcels = values?.returnFromNumberOfParcels;
    obj.returnFromTotalWeight = values?.returnFromTotalWeight;
    obj.returnReference = values?.returnReference;
  }

  return obj;
};

export const setAddressBookBtnText = (formValues, formSection, addressBook) => {
  let btnText = "Add to address book";
  const shortName = formValues[`${formSection}ShortName`];
  const existingAddress = addressBook.some(
    address => address.shortName === shortName
  );

  if (existingAddress) {
    btnText = "Update address book";
  }

  return btnText;
};
