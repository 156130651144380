import { CUSTOM_DOMAIN_LIST } from "../constants/config";

export const isCustomDomain = hostname =>
  CUSTOM_DOMAIN_LIST.some(domain => hostname.endsWith(domain));

export const getApiOrigin = (location = window.location) => {
  const { hostname, protocol } = location;

  if (isCustomDomain(hostname)) {
    return `${protocol}//apis.${hostname}`;
  }
  return process.env.REACT_APP_API_URL;
};
