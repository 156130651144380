import PropTypes from "prop-types";

import { EstimatedDateParcelInstructions } from "../EstimatedDateParcelInstructions/EstimatedDateParcelInstructions";
import { ParcelDetails } from "../ParcelDetails/ParcelDetails";
import { ReturnStatus } from "../ReturnStatus/ReturnStatus";

export const EstimatedDateParcelView = ({ parcel }) => {
  return (
    <>
      <ReturnStatus parcel={parcel} />
      <EstimatedDateParcelInstructions parcel={parcel} />
      <ParcelDetails data={parcel} />
    </>
  );
};

EstimatedDateParcelView.propTypes = {
  parcel: PropTypes.object,
};
