import PropTypes from "prop-types";

import styles from "../ParcelAdditionalStatus.module.scss";

const SubText = () => (
  <div className={styles.subtext}>
    {
      "*This date is dependent on conditions improving and may change. Please check here for regular updates."
    }
  </div>
);

export const IssueStatus = ({
  estimatedDeliveryDate,
  issueName,
  issueDelayDays,
}) => {
  return (
    <span className={styles.issue_status}>
      {issueDelayDays !== null
        ? `There is currently a ${issueDelayDays} day delay in your area due to ${issueName}*`
        : `There are currently delays in your area due to ${issueName}`}
      {(issueDelayDays !== null || estimatedDeliveryDate !== null) && (
        <SubText />
      )}
    </span>
  );
};

IssueStatus.propTypes = {
  issueName: PropTypes.string,
  issueDelayDays: PropTypes.number,
  estimatedDeliveryDate: PropTypes.string,
};
