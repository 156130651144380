import { formatMessage } from "./string";

const FAILED_TO_FETCH = "Failed to fetch";
const SOMETHING_WENT_WRONG = "Something went wrong";
const FAILED_TO_FETCH_ERROR_MESSAGE_$ = `${FAILED_TO_FETCH} {0}`;
export const SHOW_ALERT_DISPLAY_TIME = 10000; // 10000ms

export const getErrorMessage = (e, failedToFetchEntityName) => {
  if (e.errors && e.errors[0] && e.errors[0].message) {
    return e.errors[0].message;
  }

  if (e.errors && e.errors.message) {
    return e.errors.message;
  }

  if (e.message) {
    if (e.message && e.message.includes(FAILED_TO_FETCH)) {
      return formatMessage(
        FAILED_TO_FETCH_ERROR_MESSAGE_$,
        failedToFetchEntityName
      );
    }

    return e.message;
  }

  return SOMETHING_WENT_WRONG;
};

export const getErrorBody = (...args) => ({
  message: getErrorMessage(...args),
  displayTime: SHOW_ALERT_DISPLAY_TIME,
});
