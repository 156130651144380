import PropTypes from "prop-types";

import { FallBackImage } from "@dpdgroupuk/mydpd-ui";

import map from "../../../../../assets/images/map.png";
import styles from "./MapView.module.scss";

export const MapView = ({ src }) => (
  <FallBackImage className={styles.image} src={src} fallBackSrc={map} />
);

MapView.propTypes = {
  src: PropTypes.string.isRequired,
};
