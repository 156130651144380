export const CREATE_RETURN = "/create";
export const SEARCH = "/search";
export const RETURNS = "/returns";
export const DASHBOARD = "/dashboard";
export const FIND_SHOP = "/shop";
export const DPD_PICKUP_SHOP =
  "https://www.dpd.co.uk/apps/shopfinder/index.jsp";
export const DPD_LOCAL_PICKUP_SHOP =
  "https://www.dpdlocal.co.uk/apps/shopfinder/index.jsp";

export const QA_DPD_PICKUP_SHOP =
  "https://qa5-www.dpd.co.uk/apps/shopfinder/index.jsp";
export const QA_DPD_LOCAL_PICKUP_SHOP =
  "https://qa5-www.dpdlocal.co.uk/apps/shopfinder/index.jsp";

export const GET_PICKUP_SHOP_URL = businessUnit => {
  if (process.env.NODE_ENV !== "production") {
    return businessUnit === 1 ? QA_DPD_PICKUP_SHOP : QA_DPD_LOCAL_PICKUP_SHOP;
  }

  return businessUnit === 1 ? DPD_PICKUP_SHOP : DPD_LOCAL_PICKUP_SHOP;
};

export const RETURN_LINKS = [
  {
    name: "Create Return",
    href: "/create",
  },
  {
    name: "Search",
    href: "/search",
  },
  {
    name: "Dashboard",
    href: "/dashboard",
  },
  {
    name: "Find Shop",
    href: "/shop",
  },
];
