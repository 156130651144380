import { useMemo } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { connect, useSelector } from "react-redux";
import { compose, lifecycle, withHandlers, withProps } from "recompose";

import { AuthUtil, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import {
  Card,
  Legend,
  Main,
  Step,
  withLoader,
  withOverlay,
  withPrompt,
} from "@dpdgroupuk/mydpd-ui";
import { PARCEL_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import { Delimiter } from "../../../components/Delimiter/Delimiter";
import {
  ADD_TO_WATCHLIST_PROMPT_HEADER,
  ADD_TO_WATCHLIST_PROMPT_MESSAGE,
  REMOVE_FROM_WATCHLIST_PROMPT_HEADER,
  REMOVE_FROM_WATCHLIST_PROMPT_MESSAGE,
  WATCHLIST_PROMPT_ERROR,
} from "../../../constants/strings";
import { getParcelComponent } from "../../../helpers/ParcelView";
import {
  AuthSelectors,
  ParcelViewActions,
  ReturnActions,
} from "../../../redux";
import { AdditionalInfo } from "./components/AdditionalInfo/AdditionalInfo";
import { Consignment } from "./components/Consignment/Consignment";
import { DeliveryHistory } from "./components/DeliveryHistory/DeliveryHistory";
import { ReturnOptions } from "./components/ReturnOptions/ReturnOptions";

const ParcelView = ({ onOptionClick, authUser }) => {
  const { actions, parcelEvents, relatedParcels, parcel } = useSelector(
    state => state.ui.parcelView
  );

  const ParcelComponent = useMemo(() => {
    return getParcelComponent(parcel.parcelStatusType);
  }, [parcel]);

  return (
    <Main.Body>
      <Legend
        leftMessage="Shop Returns Search"
        rightMessage={`${AuthUtil.getDisplayName(authUser?.user)} (Account: ${
          authUser?.user?.account
        })`}
      />
      <Card.Stack fluid>
        <Col sm={12} className="parcelView">
          {!isEmpty(parcel) && (
            <Step
              title={`Parcel ${parcel.parcelNumber} (${parcel.accountName})`}
              helpModalTitle={"Shop Returns Search"}
            >
              <ParcelComponent parcel={parcel} parcelEvents={parcelEvents} />
              <Delimiter />
            </Step>
          )}
          {!isEmpty(actions) && (
            <Step title="Available Options">
              <ReturnOptions options={actions} onOptionClick={onOptionClick} />
              <Delimiter />
            </Step>
          )}
          {!isEmpty(parcelEvents) && (
            <Step title="Delivery History">
              <DeliveryHistory data={parcelEvents} />
              <Delimiter />
            </Step>
          )}
          {!isEmpty(relatedParcels.consignment) && (
            <Step title="Consignment">
              <Consignment data={relatedParcels.consignment} />
              <Delimiter />
            </Step>
          )}
          {!isEmpty(parcel.additionalInfo) && (
            <Step title="Additional Information">
              <AdditionalInfo data={parcel.additionalInfo} />
            </Step>
          )}
        </Col>
      </Card.Stack>
    </Main.Body>
  );
};

ParcelView.propTypes = {
  onOptionClick: PropTypes.any,
  user: PropTypes.object,
  authUser: PropTypes.object,
};

export default compose(
  connect(),
  connect(null, {
    onConfirmAddToWatchList: ParcelViewActions.addParcelToWatchList,
    onConfirmRemoveFromWatchList: ParcelViewActions.removeParcelFromWatchList,
  }),
  connect(
    state => ({
      user: AuthSelectors.getAuthUser(state),
    }),
    dispatch => ({
      fetchParcelReturns: parcelCode =>
        dispatch(ReturnActions.fetchParcelReturns(parcelCode)),
      fetchParcelCodes: parcelCode =>
        dispatch(ReturnActions.fetchParcelCodes(parcelCode)),
      fetchParcelByParcelCode: parcelCode =>
        dispatch(ParcelViewActions.fetchParcelByParcelCode(parcelCode)),
      fetchReturnActions: parcelCode =>
        dispatch(ParcelViewActions.fetchReturnActions(parcelCode)),
      fetchParcelEvents: parcelCode =>
        dispatch(ParcelViewActions.fetchParcelEvents(parcelCode)),
      fetchRelatedParcels: parcelCode =>
        dispatch(ParcelViewActions.fetchRelatedParcels(parcelCode)),
      resetParcelView: () => dispatch(ParcelViewActions.resetParcelView()),
      resetReturns: () => dispatch(ReturnActions.resetReturns()),
    })
  ),
  withLoader({
    loadFn: async ({
      fetchParcelReturns,
      fetchParcelByParcelCode,
      fetchReturnActions,
      fetchParcelEvents,
      fetchRelatedParcels,
      match,
      user,
    }) => {
      const parcelCode = match.params.parcelCode;
      const parcelNumber = parcelCode.split("*")[0];

      // If user navigated from the search page
      if (match.path.includes("search")) {
        await fetchParcelReturns({
          parcelNumber,
          businessUnit: user.businessId,
        });
      }

      await fetchParcelByParcelCode({ parcelCode });
      await fetchReturnActions(parcelCode);
      await fetchParcelEvents(parcelCode);
      await fetchRelatedParcels(parcelCode);
    },
  }),
  withAppUserPreferences,
  withOverlay,
  withPrompt,
  withProps(({ prompt, dispatch }) => ({
    watchListHandler: (
      parcelCode,
      { header, message, onClickConfirm, onClickReject }
    ) => {
      prompt.showConfirmation({
        header,
        message,
        onConfirm: () =>
          onClickConfirm(parcelCode)
            .then(() =>
              dispatch(ParcelViewActions.fetchReturnActions(parcelCode))
            )
            .catch(() => {
              prompt.showInfo({
                message: WATCHLIST_PROMPT_ERROR,
              });
            }),
        onReject: onClickReject,
      });
    },
  })),
  withHandlers(
    ({
      match,
      watchListHandler,
      onConfirmAddToWatchList,
      onConfirmRemoveFromWatchList,
      onRejectAddToWatchList,
      onRejectRemoveFromWatchList,
    }) => {
      return {
        onOptionClick: () => option => {
          switch (option) {
            case PARCEL_ACTION_CODE.WTC:
              watchListHandler(match.params.parcelCode, {
                header: ADD_TO_WATCHLIST_PROMPT_HEADER,
                message: ADD_TO_WATCHLIST_PROMPT_MESSAGE,
                onClickConfirm: onConfirmAddToWatchList,
                onClickReject: onRejectAddToWatchList,
              });
              break;
            case PARCEL_ACTION_CODE.WTR:
              watchListHandler(match.params.parcelCode, {
                header: REMOVE_FROM_WATCHLIST_PROMPT_HEADER,
                message: REMOVE_FROM_WATCHLIST_PROMPT_MESSAGE,
                onClickConfirm: onConfirmRemoveFromWatchList,
                onClickReject: onRejectRemoveFromWatchList,
              });
              break;
          }
        },
      };
    }
  ),
  lifecycle({
    componentWillUnmount() {
      this.props.resetReturns();
      this.props.resetParcelView();
    },
  })
)(ParcelView);
