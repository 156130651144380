import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { addressBook } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchAddressBook = createAsyncAction(
  pageSize =>
    addressBook.fetchAddressBook(pageSize).then(res => {
      const {
        data: { results },
      } = res;

      return results.sort((a, b) => {
        if (a.shortName.toLowerCase() < b.shortName.toLowerCase()) return -1;
        if (a.shortName.toLowerCase() > b.shortName.toLowerCase()) return 1;
        return 0;
      });
    }),
  ActionTypes.FETCH_ADDRESS_BOOK
);

export const fetchAddressBookById = createAsyncAction(
  (id, query) => addressBook.fetchAddressBookById(id, query),
  ActionTypes.FETCH_ADDRESS_BOOK_BY_ID
);

export const fetchDefaultAddress = createAsyncAction(
  query => addressBook.fetchDefaultAddress(query),
  ActionTypes.FETCH_DEFAULT_ADDRESS
);

export const setReturnFromSelectedAddress = address => ({
  type: ActionTypes.SET_RETURN_FROM_SELECTED_ADDRESS,
  address,
});

export const setReturnToSelectedAddress = address => ({
  type: ActionTypes.SET_RETURN_TO_SELECTED_ADDRESS,
  address,
});

export const clearCreateReturnSelectedAddresses = () => ({
  type: ActionTypes.CLEAR_CREATE_RETURN_SELECTED_ADDRESSES,
});

export const clearAddressBook = () => ({
  type: ActionTypes.CLEAR_ADDRESS_BOOK,
});
