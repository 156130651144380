import PropTypes from "prop-types";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import { issueIcons, statusIcons } from "../../../../../constants/forms";
import { IssueStatus } from "./components/IssueStatus";
import { ParcelStatusIcon } from "./components/ParcelStatusIcon";
import { ReasonStatus } from "./components/ReasonStatus";
import styles from "./ParcelAdditionalStatus.module.scss";

export const ParcelAdditionalStatus = ({ parcel }) => {
  const {
    parcelStatusType,
    issueDetails: { issueName, issueCode, issueDelayDays },
    rfiReason,
    estimatedDeliveryDate,
  } = parcel;

  return (
    <div className={styles.inner}>
      {parcelStatusType === PARCEL_STATUS.ISSUE && (
        <>
          <ParcelStatusIcon icon={issueIcons[issueCode]} />
          <IssueStatus
            issueName={issueName}
            issueDelayDays={issueDelayDays}
            estimatedDeliveryDate={estimatedDeliveryDate}
          />
        </>
      )}
      {parcelStatusType === PARCEL_STATUS.RFI_REASON && (
        <>
          <ParcelStatusIcon icon={statusIcons[PARCEL_STATUS.RFI_REASON]} />
          <ReasonStatus {...rfiReason} />
        </>
      )}
    </div>
  );
};

ParcelAdditionalStatus.propTypes = {
  parcel: PropTypes.object,
};
