import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { ReactComponent as Plug } from "../../../../../assets/icons/plug.svg";
import { ParcelStatusInfo } from "../ParcelStatusInfo/ParcelStatusInfo";
import styles from "./EtaBar.module.scss";
import { Progress } from "./Progress/Progress";
import { Status } from "./Status/Status";

export const EtaBar = ({
  progressData,
  isVehicleElectric,
  electricMessage,
  showIdMessage,
  parcelStatusInfoMessage,
}) => {
  return (
    progressData.showProgressBar && (
      <Container className={styles.container}>
        <Row>
          <Col lg={8}>
            <Progress {...progressData} isVehicleElectric={isVehicleElectric} />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Status
              routeStatus={progressData.progressStatus}
              statusClassName={styles.status}
            />
          </Col>
          {isVehicleElectric && (
            <Col sm={12}>
              <div className={styles.block}>
                <Plug />
                <Status
                  routeStatus={electricMessage}
                  textClassName={styles.electric}
                />
              </div>
            </Col>
          )}
          {showIdMessage && (
            <Col sm={12}>
              <ParcelStatusInfo routeStatus={parcelStatusInfoMessage} />
            </Col>
          )}
        </Row>
      </Container>
    )
  );
};

EtaBar.propTypes = {
  routeStatus: PropTypes.string,
  progressData: PropTypes.object,
  progressStatus: PropTypes.string,
  parcelStatusInfoMessage: PropTypes.string,
  electricMessage: PropTypes.string,
  isVehicleElectric: PropTypes.bool,
  showIdMessage: PropTypes.bool,
};
