import { get, isString } from "lodash";

import {
  GB_POSTCODE_PATTERN,
  IE_POSTCODE_PATTERN,
} from "@dpdgroupuk/mydpd-express-helpers/validators/regex";

import { createErrorBody } from "../utils/joiReduxForm";
import { FIRST_TWO_CHARACTERS } from "./regex";

const BIC7_OPTIONS = {
  A: "([A-Z])",
  B: "([A-Z])",
  N: "([0-9])",
  0: "([0-9])",
  "?": "([A-Z])([0-9])",
  X: "([A-Z])([0-9])",
};

const generateRegexForBIC7PostCodePattern = (bic7PostCodePattern = "") => {
  const regex = Array.from(bic7PostCodePattern).reduce(
    (acc, char) => (acc += BIC7_OPTIONS[char]),
    "^"
  );
  return regex.concat("$");
};

const generateRegexPatterns = postcodePattern => {
  const patterns = !Array.isArray(postcodePattern)
    ? Array.from(postcodePattern).split(",")
    : postcodePattern;

  return patterns
    .filter(pattern => isString(pattern))
    .map(pattern => generateRegexForBIC7PostCodePattern(pattern));
};

const postcodeIsNotValidError = postcodePath =>
  createErrorBody(postcodePath, "Postcode is not a valid Postcode");

const validateInternationalPostcodeWithPatterns = (
  postcodePattern,
  postcodePath,
  postcodeValue
) => {
  if (postcodePattern) {
    const regexPatterns = generateRegexPatterns(postcodePattern);
    const isValid = regexPatterns.some(pattern =>
      RegExp(pattern).test(postcodeValue)
    );

    if (!isValid) {
      return postcodeIsNotValidError(postcodePath);
    }
  }
};

const validateInternationalPostcode = (
  flagPostCodeNo,
  postcodeValue,
  countryCode,
  postcodePattern,
  postcodePath
) => {
  if (flagPostCodeNo) {
    return;
  }

  if (countryCode === "US" && postcodeValue.match(FIRST_TWO_CHARACTERS)) {
    const postcodeWithoutFirstTwoCharacters = postcodeValue.substring(2);
    return validateInternationalPostcodeWithPatterns(
      postcodePattern,
      postcodePath,
      postcodeWithoutFirstTwoCharacters
    );
  }

  const postcodeWithoutCountryCode = postcodeValue.startsWith(countryCode)
    ? postcodeValue.substring(2)
    : postcodeValue;
  return validateInternationalPostcodeWithPatterns(
    postcodePattern,
    postcodePath,
    postcodeWithoutCountryCode
  );
};

export const postcodeValidation = (props, countryValue, postcodePath) => {
  const postcodeValue = get(props, `values.${postcodePath}`);

  if (!postcodeValue) {
    return;
  }

  const countryCode = get(countryValue, "countryCode", "");
  const flagPostCodeNo = get(countryValue, "flagPostCodeNo", "");
  const postcodePattern = get(countryValue, "postcodePattern", "");

  if (countryCode === "GB") {
    if (GB_POSTCODE_PATTERN.test(postcodeValue)) {
      return;
    }

    return postcodeIsNotValidError(postcodePath);
  }

  if (countryCode === "IE") {
    if (IE_POSTCODE_PATTERN.test(postcodeValue)) {
      return;
    }

    return postcodeIsNotValidError(postcodePath);
  }

  return validateInternationalPostcode(
    flagPostCodeNo,
    postcodeValue,
    countryCode,
    postcodePattern,
    postcodePath
  );
};
