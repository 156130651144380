import { zipWith } from "lodash";
import PropTypes from "prop-types";

import { Table } from "@dpdgroupuk/mydpd-ui";

import { formatDDMMMYYYY } from "../../../../../utils/date";
import { ImageViewCell } from "./components/ImageViewCell";
import { MapViewCell } from "./components/MapViewCell";
import styles from "./DeliveryHistory.module.scss";

const renderHistoryEvent = data => {
  const { rowData } = data;
  const notificationName = rowData.notificationName
    ? rowData.notificationName.split(",")
    : rowData.notificationName;
  const notificationMethodName = rowData.notificationName
    ? rowData.notificationMethodName.split(",")
    : rowData.notificationName;
  const notification = zipWith(
    notificationName,
    notificationMethodName,
    (a, b) => [a + " " + b + " notification sent "]
  );

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: rowData.eventText,
        }}
      />

      {(rowData.notificationName || rowData.notificationMethodName) && (
        <>
          <br />
          {notification.map((value, index, array) => (
            <span key={index}>
              {value}
              {index < array.length - 1 && <span>{"& "}</span>}
            </span>
          ))}
        </>
      )}
    </>
  );
};

const columns = [
  {
    text: "Date",
    render: ({ rowData }) => formatDDMMMYYYY(rowData.eventDate),
    style: {
      width: "140px",
    },
  },
  {
    text: "Time",
    render: ({ rowData }) =>
      rowData.eventTime && rowData.eventTime.match(/^[0-9]{2}[:][0-9]{2}/)[0],
    style: {
      width: "140px",
    },
  },
  {
    text: "Location",
    dataIndex: "eventLocation",
    style: {
      width: "140px",
    },
  },
  {
    text: "Event",
    render: renderHistoryEvent,
    style: {
      width: "140px",
    },
  },
  {
    text: "Map",
    component: MapViewCell,
    style: {
      width: "140px",
    },
  },
  {
    text: "Image",
    component: ImageViewCell,
    style: {
      width: "140px",
    },
  },
];

export const DeliveryHistory = ({ data }) => {
  return (
    <div className={styles.container}>
      <Table columns={columns} data={data} bordered selectable={false} />
    </div>
  );
};

DeliveryHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
