import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import {
  formatPickupCountries,
  normaliseTelephoneNumber,
} from "../../../../../utils/format";
import { getFormErrors } from "../../../../../utils/forms";
import { PostcodeInput } from "../PostcodeInput/PostcodeInput";
import ShortNameInput from "../ShortNameInput/ShortNameInput";
import styles from "./ReturnFrom.module.scss";

export const ReturnFrom = ({ updateFormField, withAutocomplete }) => {
  const { pickupCountries } = useSelector(state => state.ui.createReturn);
  const { syncErrors } = useSelector(state => state.form.return);
  const formErrors = getFormErrors("returnFrom", syncErrors);

  return (
    <Step
      title="Return From"
      step={1}
      totalSteps={3}
      withStepCounter
      helpModalTitle={"Return From"}
      complete={isEmpty(formErrors)}
    >
      <ShortNameInput
        formSection="returnFrom"
        updateFormField={updateFormField}
      />
      <Field
        component={FormControl.Dropdown}
        name="returnFromCountry"
        label="Country"
        values={formatPickupCountries(pickupCountries)}
        helperText="Please select country of return"
        style={{ textTransform: "uppercase" }}
        required
      />
      <PostcodeInput
        formSection="returnFrom"
        updateFormField={updateFormField}
        withAutocomplete={withAutocomplete}
      />
      <Field
        component={FormControl.Input}
        name="returnFromOrganisation"
        label="Organisation / Name"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
      />
      <Field
        component={FormControl.Input}
        name="returnFromAddress1"
        label="Address 1"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
        required
      />
      <Field
        component={FormControl.Input}
        name="returnFromAddress2"
        label="Address 2"
        helperText="max 35 characters"
        style={{ textTransform: "uppercase" }}
        maxLength={35}
      />
      <Field
        component={FormControl.Input}
        name="returnFromCity"
        label="City"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
        required
      />
      <Field
        component={FormControl.Input}
        name="returnFromCounty"
        label="County / State"
        helperText="max 35 characters"
        style={{ textTransform: "uppercase" }}
        maxLength={35}
      />
      <Field
        component={FormControl.Input}
        name="returnFromName"
        label="Name"
        helperText="max 35 characters"
        maxLength={35}
      />
      <Field
        component={FormControl.Input}
        name="returnFromTelephone"
        label="Telephone"
        helperText="Enter UK telephone number"
        minLength={7}
        maxLength={15}
        normalize={normaliseTelephoneNumber}
      />
      <Field
        component={FormControl.Input}
        name="returnFromEmail"
        label="Email"
        helperText=" "
        maxLength={100}
      />
      <div className={styles.flexGap}>
        <Field
          className="pl-4"
          type="number"
          component={FormControl.Input}
          name="returnFromNumberOfParcels"
          label="No Of Parcels"
          helperText=" "
          required
        />
        <Field
          component={FormControl.Input}
          name="returnFromTotalWeight"
          label="Total Weight"
          helperText="max weight of 20KG per parcel"
          maxLength={5}
          required
        />
      </div>
      <Field
        component={FormControl.Input}
        name="returnReference"
        label="Return Reference"
        helperText="max 25 characters"
        maxLength={25}
      />
    </Step>
  );
};

ReturnFrom.propTypes = {
  updateFormField: PropTypes.func,
  withAutocomplete: PropTypes.bool,
};
