import { trim } from "lodash";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import {
  createCancelableRequest,
  isAbortError,
} from "@dpdgroupuk/fetch-client";
import PostcodeFinder from "@dpdgroupuk/mydpd-postcode-finder";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { search } from "../../redux/postcodeFinderInput/actions";
import { getErrorMessage, SHOW_ALERT_DISPLAY_TIME } from "../../utils/error";
import { omitNilValues } from "../../utils/object";

export default compose(
  withSnackbar,
  connect(null, (dispatch, { snackbar }) => ({
    search: createCancelableRequest(async (values, options) => {
      // Remove all whitespace from values
      Object.keys(values).forEach(key => (values[key] = trim(values[key])));

      try {
        return await dispatch(search(omitNilValues(values), options));
      } catch (err) {
        if (!isAbortError(err)) {
          snackbar.showAlert({
            message: getErrorMessage(err, "postcodes"),
            displayTime: SHOW_ALERT_DISPLAY_TIME,
          });
        }
      }
    }),
  })),
  withHandlers({
    onSelectAddress: props => values => props.onSelectionChange(values),
  })
)(PostcodeFinder);
