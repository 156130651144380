import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withProps } from "recompose";
import { compose } from "redux";

import { Banner, Tile } from "@dpdgroupuk/mydpd-ui";

import { AuthSelectors, ReturnActions } from "../../../../../redux";
import { RETURNS, SEARCH } from "../../../../../router/constants";
import { stringifyQuery } from "../../../../../utils/query";

const StatTile = ({
  title,
  headerTitle,
  disabled,
  date,
  account,
  pot,
  businessUnit,
  fetchReturnsDashboardFindbyCode,
  fetchParcelCodes,
  setAccount,
  setFindby,
  setSearchFindbyCode,
  banner,
}) => {
  const history = useHistory();
  const { stats } = useSelector(state => state.ui.dashboard);

  const handleTileClick = async () => {
    const request = {
      accountNumber: account,
      pot,
      businessUnit,
    };

    if (request.pot !== "atShop") {
      request.estimatedDeliveryDate = date;
    }

    if (request.pot === "expectedAfter") {
      const expectedAfterDates = stats.expectedAfterDate.expectedDate;
      const dates = expectedAfterDates.slice(1, expectedAfterDates.length);
      request.estimatedDeliveryDate = dates;
    }

    try {
      const { findByCode, findByTotal } = await fetchReturnsDashboardFindbyCode(
        request
      );

      if (!isEmpty(findByCode)) {
        await setAccount(account);
        await setFindby(findByCode, findByTotal);
        await setSearchFindbyCode(findByCode, findByTotal);

        if (findByTotal === 1) {
          const { parcelCodes } = await fetchParcelCodes({
            findByCode,
            startPos: 1,
            endPos: 25,
          });

          history.push(`${SEARCH}/${parcelCodes[0]}`, { from: "dashboard" });
        } else {
          const queryParams = {
            account: request.accountNumber,
            pot: request.pot,
            businessUnit,
          };

          if (request.pot !== "atShop") {
            queryParams.deliveryDate = request.estimatedDeliveryDate;
          }

          history.push(`${RETURNS}?${stringifyQuery(queryParams)}`, {
            from: "dashboard",
          });
        }
      }
    } catch (error) {
      banner.removeAll();
      banner.showByType("alert", {
        message: "No results found",
        closable: true,
        showIcon: true,
        actions: [],
      });
    }
  };

  return (
    <>
      <Tile
        key={title}
        title={
          <>
            <span>{title}</span>
            <div>{date}</div>
          </>
        }
        disabled={disabled}
        onClick={handleTileClick}
      >
        {headerTitle}
      </Tile>
    </>
  );
};

StatTile.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  headerTitle: PropTypes.number,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  icon: PropTypes.func,
  account: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pot: PropTypes.string,
  businessUnit: PropTypes.string,
  fetchReturnsDashboardFindbyCode: PropTypes.func,
  fetchParcelCodes: PropTypes.func,
  setAccount: PropTypes.func,
  setFindby: PropTypes.func,
  setSearchFindbyCode: PropTypes.func,
  banner: PropTypes.object,
};

StatTile.defaultProps = {
  classes: {},
};

export default compose(
  Banner.withBanner,
  connect(
    state => ({
      businessUnit: AuthSelectors.getBusinessUnit(state),
    }),
    dispatch => ({
      fetchReturnsDashboardFindbyCode: params =>
        dispatch(ReturnActions.fetchReturnsDashboardFindbyCode(params)),
      fetchParcelCodes: params =>
        dispatch(ReturnActions.fetchParcelCodes(params)),
      setAccount: params => dispatch(ReturnActions.setAccount(params)),
      setFindby: (code, total) =>
        dispatch(ReturnActions.setFindby(code, total)),
      setSearchFindbyCode: params =>
        dispatch(ReturnActions.setSearchFindbyCode(params)),
    })
  ),
  withProps(({ banner }) => ({
    banner,
  }))
)(StatTile);
