import { useEffect, useState } from "react";

import { isNull } from "lodash";
import PropTypes from "prop-types";

import { Table } from "@dpdgroupuk/mydpd-ui";

import { addSpacesToCamelCase } from "../../../../../utils/string";

export const AdditionalInfo = ({ data }) => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const columns = [];

    for (const [key, value] of Object.entries(data)) {
      if (key === "partner" && !isNull(value)) {
        // TODO: Implement logic for additional info table
        continue;
      } else if (!isNull(value)) {
        columns.push({
          text: addSpacesToCamelCase(key),
          dataIndex: key,
          style: {
            width: "100px",
          },
        });
      }
    }

    setColumns(columns);
  }, [data]);

  return <Table columns={columns} data={data} bordered />;
};

AdditionalInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
