import ActionTypes from "./actionTypes";

export const initialState = {
  parcel: {},
  actions: {},
  parcelEvents: {},
  relatedParcels: {},
  route: {},
  driver: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PARCEL.SUCCESS: {
      return {
        ...state,
        parcel: action.payload,
      };
    }
    case ActionTypes.GET_PARCEL.FAILURE: {
      return {
        ...state,
        parcel: initialState.parcel,
      };
    }
    case ActionTypes.GET_RETURN_ACTIONS.SUCCESS: {
      return {
        ...state,
        actions: action.payload,
      };
    }
    case ActionTypes.GET_RETURN_ACTIONS.FAILURE: {
      return {
        ...state,
        actions: initialState.actions,
      };
    }
    case ActionTypes.GET_PARCEL_EVENTS.SUCCESS: {
      return {
        ...state,
        parcelEvents: action.payload,
      };
    }
    case ActionTypes.GET_PARCEL_EVENTS.FAILURE: {
      return {
        ...state,
        parcelEvents: initialState.parcelEvents,
      };
    }
    case ActionTypes.GET_RELATED_PARCELS.SUCCESS: {
      return {
        ...state,
        relatedParcels: action.payload,
      };
    }
    case ActionTypes.GET_RELATED_PARCELS.FAILURE: {
      return {
        ...state,
        relatedParcels: initialState.relatedParcels,
      };
    }
    case ActionTypes.RESET_PARCEL_VIEW: {
      return {
        ...initialState,
      };
    }
    case ActionTypes.GET_ROUTE.SUCCESS: {
      return {
        ...state,
        route: action.payload,
      };
    }
    case ActionTypes.GET_ROUTE.FAILURE: {
      return {
        ...state,
        route: initialState.route,
      };
    }
    case ActionTypes.GET_DRIVER.SUCCESS: {
      return {
        ...state,
        driver: action.payload,
      };
    }
    case ActionTypes.GET_DRIVER.FAILURE: {
      return {
        ...state,
        driver: initialState.driver,
      };
    }
    default:
      return state;
  }
};
