import { createSelector } from "reselect";

export const getData = state => state.app.ums;

export const getPreferences = createSelector(
  getData,
  ({ preferences }) => preferences
);
export const getCustomer = createSelector(getData, ({ customer }) => customer);
export const getCustomers = createSelector(
  getData,
  ({ customers }) => customers
);

export const getAdvancedCustomerConfiguration = createSelector(
  getData,
  ({ advancedConfiguration }) => advancedConfiguration
);
export const getShippingSettings = createSelector(
  getData,
  ({ shippingSettings }) => shippingSettings
);
export const getCustomerAddress = createSelector(
  getData,
  ({ customerAddress }) => customerAddress
);
export const getSecuritySettings = createSelector(
  getData,
  ({ securitySettings }) => securitySettings
);
