import _ from "lodash";

export const omitNilValues = obj => _.pickBy(obj, _.identity);

export const getAllObjectKeysWhereValueIsTrue = object =>
  Object.keys(object).filter(key => object[key]) ?? [];

export const replaceEmptyStringToUndefined = obj =>
  _.mapValues(obj, value => {
    if (_.isString(value) && value.trim() === "") {
      value = undefined;
    } else if (value === null) {
      value = undefined;
    } else if (_.isPlainObject(value)) {
      value = replaceEmptyStringToUndefined(value);
    }

    return value;
  });

export const getValue = (object, path, defaultValue) => {
  const value = _.get(object, path);
  if (value === undefined || value === null) {
    return defaultValue;
  } else {
    return value;
  }
};

export const setUndefinedToEmptyRequiredFields = (values, requiredKeys) => {
  const result = _.cloneDeep(values);
  _.forEach(requiredKeys, path => {
    _.set(result, path, getValue(values, path, ""));
  });
  return replaceEmptyStringToUndefined(result);
};
