import api from "./api";

export const fetchReturnsDashboard = query =>
  api.get({
    path: "/v1/returns/dashboard",
    query,
  });

export const fetchReturnsDashboardFindbyCode = query =>
  api.get({
    path: "/v1/returns/dashboard/pot",
    query,
  });
