import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";

import styles from "./ReviewCard.module.scss";

export const ReviewCard = ({ action, title, className, children, errors }) => {
  const borderColor = isEmpty(errors) ? "#8ac149" : "#fe9400";

  return (
    <Card
      className={classNames(styles.reviewCard, className)}
      style={{ border: `1px solid ${borderColor}` }}
    >
      <Card.Title className={styles.title}>
        <Row className={styles.reviewRow}>
          <Col md={7}>{title}</Col>
          {action && (
            <Col md={5} className={styles.label}>
              {action}
            </Col>
          )}
        </Row>
      </Card.Title>
      <Card.Body className={styles.body}>{children}</Card.Body>
    </Card>
  );
};

ReviewCard.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  action: PropTypes.node,
  errors: PropTypes.object,
};
