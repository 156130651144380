import { find } from "lodash";

import { formatDashboardStatDate } from "../utils/date";
import { createDashboardStatEnum } from "../utils/forms";
import { joinStringsWithComma } from "./string";

export const formatAccounts = accounts => {
  return accounts
    .map(account => ({
      value: account,
      label: account,
    }))
    .sort((a, b) => {
      if (a.value === "All Accounts" || b.value === "All Accounts") a = 0;
      if (a.value > b.value) return 1;
      if (a.value < b.value) return -1;
      return 0;
    });
};

export const formatAddress = address => {
  const orderedAddress = {
    organisation: address.organisation,
    countryName: address.countryName,
    street: address.street,
    county: address.county,
    postcode: address.postcode,
  };

  let str = "";
  Object.values(orderedAddress).forEach((value, index) => {
    if (value && index === Object.keys(orderedAddress).length - 1) {
      str += `${value}`;
    } else if (value) {
      str += `${value}, `;
    }
  });

  return str;
};

export const formatDashboardStats = stats => [
  createDashboardStatEnum("At Shop", stats.atShop, "atShop"),
  createDashboardStatEnum(
    "Expected",
    stats.expectedDates[0].total,
    "expected",
    formatDashboardStatDate(stats.expectedDates[0].expectedDate)
  ),
  createDashboardStatEnum(
    "Expected",
    stats.expectedDates[1].total,
    "expected2",
    formatDashboardStatDate(stats.expectedDates[1].expectedDate)
  ),
  createDashboardStatEnum(
    "Expected After",
    stats.expectedAfterDate.total,
    "expectedAfter",
    formatDashboardStatDate(stats.expectedDates[1].expectedDate)
  ),
];

export const formatProfiles = profiles => {
  return profiles
    .map(({ profileCode, profileName }) => ({
      value: profileCode,
      label: profileName,
    }))
    .sort((a, b) => {
      if (a.value > b.value) return 1;
      if (a.value < b.value) return -1;
      return 0;
    });
};

export const formatPickupCountries = pickupCountries => {
  return pickupCountries
    .map(({ countryName, countryCode }) => ({
      label: countryName,
      value: countryCode,
    }))
    .sort((a, b) => {
      // Place UK and ROI at start then sort rest in alphabetical order
      if (a.label === "United Kingdom") return -1;
      if (b.label === "United Kingdom") return 1;

      if (a.label === "Republic of Ireland") return -1;
      if (b.label === "Republic of Ireland") return 1;

      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });
};

export const formatAddressBookReq = (formSection, values, addressBookType) => {
  return {
    shortName: values?.[`${formSection}ShortName`],
    address: {
      countryCode: values?.returnFromCountry,
      county: values[`${formSection}County`]
        ? values[`${formSection}County`]
        : null,
      locality: values[`${formSection}Address2`]
        ? values[`${formSection}Address2`]
        : null,
      organisation: values[`${formSection}Organisation`]
        ? values[`${formSection}Organisation`]
        : null,
      postcode: values[`${formSection}Postcode`]
        ? values[`${formSection}Postcode`]
        : null,
      street: values[`${formSection}Address1`]
        ? values[`${formSection}Address1`]
        : null,
      town: values[`${formSection}City`] ? values[`${formSection}City`] : null,
    },
    contactDetails: {
      contactName: values[
        `${formSection}${formSection === "returnTo" ? "Contact" : ""}Name`
      ]
        ? values[
            `${formSection}${formSection === "returnTo" ? "Contact" : ""}Name`
          ]
        : null,
      telephone: values[`${formSection}Telephone`]
        ? values[`${formSection}Telephone`]
        : null,
    },
    notificationDetails: {
      email: values[`${formSection}Email`]
        ? values[`${formSection}Email`]
        : null,
    },
    readOnly: "false",
    addressBookType,
    isValid: "true",
    is_valid: "true",
  };
};

export const normaliseTelephoneNumber = value => {
  let phoneValue = value.replace(/[^+\d]/g, "");

  const plusCharacterIndex = phoneValue.lastIndexOf("+");

  if (plusCharacterIndex !== -1 && plusCharacterIndex !== 0) {
    phoneValue =
      phoneValue.slice(0, plusCharacterIndex) +
      phoneValue.slice(plusCharacterIndex + 1);
  }

  return phoneValue;
};

export const formatFullAddressString = ({
  organisation,
  street,
  suburb,
  town,
  city,
  county,
  postcode,
}) =>
  joinStringsWithComma([
    organisation,
    street,
    suburb,
    town,
    city,
    county,
    postcode,
  ]);

const valuesToUpper = mappedValues => {
  return {
    address: {
      countryCode: mappedValues?.address?.countryCode?.toUpperCase(),
      county: mappedValues?.address?.county?.toUpperCase(),
      locality: mappedValues?.address?.locality?.toUpperCase(),
      organisation: mappedValues?.address?.organisation?.toUpperCase(),
      postcode: mappedValues?.address?.postcode?.toUpperCase(),
      street: mappedValues?.address?.street?.toUpperCase(),
      town: mappedValues?.address?.town?.toUpperCase(),
    },
    contactDetails: {
      contactName: mappedValues?.contactDetails?.contactName?.toUpperCase(),
      telephone: mappedValues?.contactDetails?.telephone?.toUpperCase(),
    },
    notificationDetails: {
      email: mappedValues?.notificationDetails?.email?.toUpperCase(),
    },
  };
};

export const formattedCompareAddressbook = (mappedValues, addressBookList) => {
  const currentValues = valuesToUpper(mappedValues);

  const originalValues = find(addressBookList, {
    shortName: mappedValues.shortName,
  });

  const addressbookValues = valuesToUpper(originalValues);

  return { currentValues, addressbookValues };
};
