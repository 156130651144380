import { useCallback, useEffect, useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { Field, propTypes } from "redux-form";

import { withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { withSnackbar } from "@dpdgroupuk/mydpd-ui";

import { AutocompleteDropdown } from "../../../../../components/AutocompleteDropdown/AutocompleteDropdown";
import { AuthSelectors } from "../../../../../redux";
import { AddressBookActions } from "../../../../../redux/addressBook";
import { getAddressBookFieldValues } from "../../../../../utils/forms";

const ShortNameInput = ({
  onSearch,
  onChange,
  fetchAddressBookById,
  setReturnFromSelectedAddress,
  setReturnToSelectedAddress,
  authUser,
  updateFormField,
  formSection,
}) => {
  const { list } = useSelector(state => state.addressBook);
  const { shareProfile } = useSelector(
    state => state.app.ums.preferences.prefsConfigSettings
  );

  const [addressBookOptions, setAddressBookOptions] = useState([]);

  useEffect(() => {
    const returnFromList = [];
    const returnToList = [];

    for (const addressBookEntry of list) {
      const { addressType } = addressBookEntry;

      if (addressType === "0") {
        returnFromList.push(addressBookEntry);
      } else if (addressType === "1") {
        returnToList.push(addressBookEntry);
      }
    }

    if (formSection === "returnFrom") {
      setAddressBookOptions(returnFromList);
    } else if (formSection === "returnTo") {
      setAddressBookOptions(returnToList);
    }
  }, [list]);

  const handleAddressBookSelectionChange = async ({
    addressBookId,
    addressType,
  }) => {
    const { data } = await fetchAddressBookById(addressBookId, {
      addressBookType: addressType,
    });

    const userAccess = { read: false, delete: false, edit: false };
    if (authUser.user.userId === data.userId) {
      Object.keys(userAccess).forEach(key => {
        userAccess[key] = true;
      });
    } else if (shareProfile) {
      userAccess.read = true;
    }

    let addressBookAPI;
    if (formSection === "returnFrom") {
      addressBookAPI = setReturnFromSelectedAddress;
    } else if (formSection === "returnTo") {
      addressBookAPI = setReturnToSelectedAddress;
    }

    await addressBookAPI({ ...data, ...userAccess });
    const addressBookValues = getAddressBookFieldValues(formSection, data);
    updateFormField(addressBookValues);
  };

  const onSelectionChangeHandler = useCallback(
    values => handleAddressBookSelectionChange({ ...values }),
    [handleAddressBookSelectionChange]
  );

  return (
    <Field
      component={AutocompleteDropdown}
      label="Short Name"
      name={`${formSection}ShortName`}
      onSearch={onSearch}
      labelKey={option => option.shortName}
      options={addressBookOptions}
      optionLabelMapper={option => option.shortName}
      id={`${formSection}ShortName`}
      onSelectionChange={onSelectionChangeHandler}
      maxLength={45}
      type="search"
      minLength={1}
      onChange={onChange}
      normalize={value => (value || "").toUpperCase()}
      helperText="max 45 characters"
      async={false}
    />
  );
};

ShortNameInput.propTypes = {
  ...propTypes,
  form: PropTypes.string.isRequired,
  formErrors: PropTypes.object,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  fetchAddressBookById: PropTypes.func,
  setReturnFromSelectedAddress: PropTypes.func,
  setReturnToSelectedAddress: PropTypes.func,
  authUser: PropTypes.object,
  updateFormField: PropTypes.func,
  formSection: PropTypes.string,
};

export default compose(
  withAppUserPreferences,
  withSnackbar,
  connect(
    state => ({
      user: AuthSelectors.getAuthUser(state),
    }),
    dispatch => ({
      fetchAddressBookById: (id, query) =>
        dispatch(AddressBookActions.fetchAddressBookById(id, query)),
      setReturnFromSelectedAddress: address =>
        dispatch(AddressBookActions.setReturnFromSelectedAddress(address)),
      setReturnToSelectedAddress: address =>
        dispatch(AddressBookActions.setReturnToSelectedAddress(address)),
      onChange: value => {
        if (isEmpty(value)) {
          dispatch(AddressBookActions.clearAddressBook);
        }
      },
    })
  )
)(ShortNameInput);
