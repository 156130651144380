import ActionTypes from "./actionTypes";

export const initialState = {
  account: null,
  findByCode: null,
  searchFindByCode: null,
  findByTotal: 0,
  parcels: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_PARCEL_RETURNS.SUCCESS: {
      return {
        ...state,
        findByCode: action.payload.findby_code,
        findByTotal: action.payload.findby_total,
      };
    }
    case ActionTypes.GET_PARCEL_RETURNS.FAILURE: {
      return {
        ...state,
        findByCode: initialState.findByCode,
        findByTotal: initialState.findByTotal,
      };
    }
    case ActionTypes.GET_PARCEL.SUCCESS: {
      return {
        ...state,
        parcels: action.payload.data,
      };
    }
    case ActionTypes.GET_PARCEL.FAILURE: {
      return {
        ...state,
        parcels: initialState.parcels,
      };
    }
    case ActionTypes.GET_RETURNS_DASHBOARD_FINDBY_CODE.SUCCESS: {
      return {
        ...state,
        findByCode: action.payload.findByCode,
        findByTotal: action.payload.findByTotal,
      };
    }
    case ActionTypes.GET_RETURNS_DASHBOARD_FINDBY_CODE.FAILURE: {
      return {
        ...state,
        findByCode: initialState.findByCode,
        findByTotal: initialState.findByTotal,
      };
    }
    case ActionTypes.RESET_RETURNS: {
      return initialState;
    }
    case ActionTypes.SET_ACCOUNT: {
      return {
        ...state,
        account: action.account,
      };
    }
    case ActionTypes.SET_FINDBY: {
      return {
        ...state,
        findByCode: action.code,
        findByTotal: action.total,
      };
    }
    case ActionTypes.SET_SEARCH_FINDBY_CODE: {
      return {
        ...state,
        searchFindByCode: action.code,
      };
    }
    default:
      return state;
  }
};
