import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { dashboard } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchReturnsDashboard = createAsyncAction(
  query => dashboard.fetchReturnsDashboard(query).then(({ data }) => data),
  ActionTypes.GET_RETURNS_DASHBOARD
);

export const resetDashboard = () => ({
  type: ActionTypes.RESET_DASHBOARD,
});
