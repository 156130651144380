import api from "./api";

export const fetchAddressBook = pageSize =>
  api.get({
    path: `/addressbook?searchPage=1&searchPageSize=${pageSize}&withoutGroups=true`,
  });

export const fetchAddressBookTotalResults = () =>
  api.get({
    path: "/addressbook?searchPage=1&withoutGroups=true",
  });

export const insertAddressBook = body =>
  api.post({
    path: "/addressbook",
    body,
  });

export const updateAddressBook = (addressBookId, query, body) =>
  api.put({
    path: `/addressbook/${addressBookId}`,
    query,
    body,
  });

export const fetchAddressBookById = (id, query) =>
  api.get({
    path: `/addressbook/${id}`,
    query,
  });

export const fetchDefaultAddress = query =>
  api.get({
    path: "/addressbook/default?type=1",
    query,
  });
