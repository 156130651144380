import PropTypes from "prop-types";

export const Status = ({ routeStatus, textClassName, statusClassName }) => {
  return (
    <div className={statusClassName}>
      <span className={textClassName}>{routeStatus}</span>
    </div>
  );
};

Status.defaultProps = {
  textClassName: "",
  statusClassName: "",
};

Status.propTypes = {
  routeStatus: PropTypes.object,
  textClassName: PropTypes.string,
  statusClassName: PropTypes.string,
};
