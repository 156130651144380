import PropTypes from "prop-types";

import { Table } from "@dpdgroupuk/mydpd-ui";

import { formatYYYYMMDDDD } from "../../../../../utils/date";
import { formatFullAddressString } from "../../../../../utils/format";
import { joinStringsWithComma } from "../../../../../utils/string";
import styles from "./ParcelDetails.module.scss";

const columns = [
  {
    text: "Collection",
    dataIndex: "collectionDetails.collectionDate",
    render: ({ rowData: { collectionDetails } }) =>
      formatYYYYMMDDDD(collectionDetails.collectionDate),
    style: {
      width: "75px",
    },
  },
  {
    text: "Customer Ref",
    render: ({ rowData: { customerRef1, customerRef2, customerRef3 } }) =>
      joinStringsWithComma([customerRef1, customerRef2, customerRef3]),
    style: {
      width: "70px",
    },
  },
  {
    text: "Account",
    dataIndex: "accountCode",
    style: {
      width: "60px",
    },
  },
  {
    text: "Service",
    render: ({
      rowData: {
        service: { serviceName },
        product: { productName },
      },
    }) => `${productName} - ${serviceName}`,
    style: {
      width: "100px",
    },
  },
  {
    text: "Consignment",
    dataIndex: "collectionDetails.consignmentNumber",
    style: {
      width: "90px",
    },
  },
  {
    text: "Address",
    render: ({
      rowData: {
        deliveryDetails: { deliveryAddress },
      },
    }) => formatFullAddressString(deliveryAddress),
    style: {
      width: "120px",
    },
  },
  {
    text: "Email Address",
    dataIndex: "notificationDetails.email",
    style: {
      width: "100px",
    },
  },
  {
    text: "Phone Number",
    dataIndex: "notificationDetails.mobile",
    style: {
      width: "65px",
    },
  },
];

export const ParcelDetails = ({ data }) => {
  return (
    <div className={styles.container}>
      <Table
        columns={columns}
        data={[data]}
        tableContainerClassName={"borderless"}
      />
    </div>
  );
};

ParcelDetails.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
