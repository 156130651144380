import * as Sentry from "@sentry/react";

const { REACT_APP_SENTRY_DSN, REACT_APP_SENTRY_RELEASE, REACT_APP_SENTRY_ENV } =
  process.env;

Sentry.init({
  debug: process.env.NODE_ENV === "development",
  enabled: process.env.NODE_ENV === "production",
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  ignoreErrors: [
    "SecurityError",
    // Random plugins/extensions
    "top.GLOBALS",
    "Can't find variable: ga",
    /(ResizeObserver loop limit exceeded)/,
  ],
  ignoreUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => {
    if (action.type.startsWith("@@redux-form")) {
      return null;
    }

    return action;
  },
});

export default Sentry;
