import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { Button } from "@dpdgroupuk/mydpd-ui";

import styles from "./SubmitButton.module.scss";

export const SubmitButton = ({
  colSpan,
  btnText = "Search",
  onClick = null,
}) => {
  return (
    <Col
      md={colSpan}
      className={classNames(
        styles.submitButtonContainer,
        "row",
        "justify-content-end"
      )}
    >
      <Button
        className={styles.submitButton}
        variant="primary"
        type="submit"
        onClick={onClick}
      >
        {btnText}
      </Button>
    </Col>
  );
};

SubmitButton.propTypes = {
  colSpan: PropTypes.number,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};
