import ActionTypes from "./actionTypes";

export const initialState = {
  list: [],
  returnFromSelectedAddress: {},
  returnToSelectedAddress: {},
  defaultAddress: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_ADDRESS_BOOK.SUCCESS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case ActionTypes.FETCH_ADDRESS_BOOK.FAILURE: {
      return initialState;
    }
    case ActionTypes.FETCH_DEFAULT_ADDRESS.SUCCESS: {
      return {
        ...state,
        defaultAddress: action.payload.data,
      };
    }
    case ActionTypes.FETCH_DEFAULT_ADDRESS.FAILURE: {
      return initialState;
    }
    case ActionTypes.SET_RETURN_FROM_SELECTED_ADDRESS: {
      return {
        ...state,
        returnFromSelectedAddress: action.address,
      };
    }
    case ActionTypes.SET_RETURN_TO_SELECTED_ADDRESS: {
      return {
        ...state,
        returnToSelectedAddress: action.address,
      };
    }
    case ActionTypes.CLEAR_CREATE_RETURN_SELECTED_ADDRESSES: {
      return {
        ...state,
        returnFromSelectedAddress: initialState.returnFromSelectedAddress,
        returnToSelectedAddress: initialState.returnToSelectedAddress,
      };
    }
    case ActionTypes.CLEAR_ADDRESS_BOOK: {
      return initialState;
    }
    default:
      return state;
  }
};
