import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("SEARCH", {
  GET_RETURNS_CSV: createAsyncActionTypes("GET_RETURNS_CSV"),
  GET_FILTER_DATA: createAsyncActionTypes("GET_FILTER_DATA"),
  GET_FILTER_RETURNS: createAsyncActionTypes("GET_FILTER_RETURNS"),
  GET_RETURN_ACTIONS: createAsyncActionTypes("GET_RETURN_ACTIONS"),
  SET_FILTER_PAGINATION: "SET_FILTER_PAGINATION",
  SET_FILTER_VALUES: "SET_FILTER_VALUES",
  RESET_SEARCH_RESULTS: "RESET_SEARCH_RESULTS",
});
