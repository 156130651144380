import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("SEARCH", {
  GET_PARCEL_RETURNS: createAsyncActionTypes("GET_PARCEL_RETURNS"),
  GET_PARCEL_CODES: createAsyncActionTypes("GET_PARCEL_CODES"),
  GET_PARCEL: createAsyncActionTypes("GET_PARCEL"),
  GET_RETURNS_DASHBOARD_FINDBY_CODE: createAsyncActionTypes(
    "GET_RETURNS_DASHBOARD_FINDBY_CODE"
  ),
  RESET_RETURNS: "RESET_RETURNS",
  SET_ACCOUNT: "SET_ACCOUNT",
  SET_FINDBY: "SET_FINDBY",
  SET_SEARCH_FINDBY_CODE: "SET_SEARCH_FINDBY_CODE",
});
