import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PROJECT_NAME, Provider } from "@dpdgroupuk/mydpd-app";
import { Layout, Loader, Main } from "@dpdgroupuk/mydpd-ui";

import apisClient from "./apis/api";
import tracker from "./apis/tracker";
import { AppHeader } from "./components/Header/Header";
import { UmsActions } from "./redux";
import { Routes } from "./router/Routes";

import "@dpdgroupuk/mydpd-ui/styles/index.scss";

const App = ({ fetchInitialData }) => {
  return (
    <Provider
      apis={apisClient}
      projectName={PROJECT_NAME.PICKUP_RETURNS}
      tracker={tracker}
    >
      <Loader loadFn={fetchInitialData}>
        <Layout header={<AppHeader />}>
          <Main>
            <Routes />
          </Main>
        </Layout>
      </Loader>
    </Provider>
  );
};

App.propTypes = {
  fetchInitialData: PropTypes.func,
};

const mapDispatchToProps = {
  fetchInitialData: UmsActions.fetchInitialData,
};

export default connect(null, mapDispatchToProps)(App);
