import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";
import { Switch, useHistory } from "react-router-dom";

import CreateReturn from "../pages/ShopReturns/CreateReturn/CreateReturn";
import DashboardAuth from "../pages/ShopReturns/Dashboard/DashboardAuth";
import ParcelView from "../pages/ShopReturns/ParcelView/ParcelView";
import ReturnSearch from "../pages/ShopReturns/Search/ReturnSearch";
import SearchResults from "../pages/ShopReturns/SearchResults/SearchResults";
import * as routes from "./constants";

export const Routes = () => {
  const history = useHistory();
  const { user } = useSelector(state => state.app.auth);

  return (
    <Switch>
      <Redirect exact from="/" to={routes.CREATE_RETURN} />
      <Route
        exact
        strict
        path={routes.CREATE_RETURN}
        component={CreateReturn}
      />
      <Route exact strict path={routes.SEARCH} component={ReturnSearch} />
      <Route exact strict path={routes.RETURNS} component={SearchResults} />
      <Route exact strict path={routes.DASHBOARD} component={DashboardAuth} />
      <Route
        exact
        strict
        path={routes.FIND_SHOP}
        component={() => {
          window.open(routes.GET_PICKUP_SHOP_URL(user.businessId), "_blank");
          history.goBack();
          return null;
        }}
      />
      <Route
        exact
        strict
        path={`${routes.SEARCH}/:parcelCode`}
        component={ParcelView}
      />
    </Switch>
  );
};
