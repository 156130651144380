import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { createReturn } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchPickupCountries = createAsyncAction(
  query => createReturn.fetchPickupCountries(query).then(res => res),
  ActionTypes.GET_PICKUP_COUNTRIES
);

export const fetchProfiles = createAsyncAction(
  query => createReturn.fetchProfiles(query).then(res => res),
  ActionTypes.GET_PROFILES
);

export const resetCreateReturn = () => ({
  type: ActionTypes.RESET_CREATE_RETURN,
});
