import { chunk } from "lodash";
import PropTypes from "prop-types";

import { ReactComponent as AddToWatchList } from "../../../../../assets/icons/add-to-watch-list.svg";
import { ReactComponent as RemoveFromWatchList } from "../../../../../assets/icons/remove-from-watch-list.svg";
import OptionItem from "./OptionItem";
import styles from "./ReturnOptions.module.scss";

export const ReturnOptions = ({ options, onOptionClick }) => {
  return (
    <div>
      {chunk(options.actions, 4).map((row, i) => (
        <div className={styles.options_row} key={i}>
          {row.map((option, k) => (
            <div className={styles.option_wrapper} key={k}>
              <OptionItem
                icon={option === "WTC" ? AddToWatchList : RemoveFromWatchList}
                title={
                  option === "WTC"
                    ? "Add to watch list"
                    : "Remove from watch list"
                }
                onClick={() => onOptionClick(option)}
                {...option}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

ReturnOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionClick: PropTypes.func,
};
