import { useEffect } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { change, Field, formValueSelector } from "redux-form";

import { FormControl, Step } from "@dpdgroupuk/mydpd-ui";

import DoubleAstericksInput from "../../../../../components/DoubleAstericksInput/DoubleAstericksInput";
import { RETURN_FORM } from "../../../../../constants/forms";
import { getSelectedCountry } from "../../../../../redux/selectors";
import { getFormErrors } from "../../../../../utils/forms";
import { PostcodeInput } from "../PostcodeInput/PostcodeInput";
import ShortNameInput from "../ShortNameInput/ShortNameInput";

const CONTACT_FIELDS = {
  RETURN_TO_CONTACT_NAME: "returnToContactName",
  RETURN_TO_ORGANISATION: "returnToOrganisation",
};

let ReturnTo = ({
  updateFormField,
  dispatch,
  withAutocomplete,
  requiredContactFields,
}) => {
  const { syncErrors } = useSelector(state => state.form.return);
  const selectedCountry = useSelector(state => getSelectedCountry(state));
  const formErrors = getFormErrors("returnTo", syncErrors);
  const { reqOrganisationName, reqContactName } = requiredContactFields;

  useEffect(() => {
    dispatch(
      change(RETURN_FORM, "returnToCountry", selectedCountry.countryName)
    );
  }, [selectedCountry]);

  return (
    <Step
      title="Return To"
      step={2}
      totalSteps={3}
      withStepCounter
      helpModalTitle={"Return To"}
      complete={isEmpty(formErrors)}
    >
      <ShortNameInput
        formSection="returnTo"
        updateFormField={updateFormField}
      />
      <Field
        component={FormControl.Input}
        name="returnToCountry"
        label="Country"
        helperText="Country defined"
        disabled
      />
      <PostcodeInput
        formSection="returnTo"
        updateFormField={updateFormField}
        withAutocomplete={withAutocomplete}
      />
      <Field
        component={DoubleAstericksInput}
        name="returnToOrganisation"
        label="Organisation"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        required={reqOrganisationName}
      />
      <Field
        component={FormControl.Input}
        name="returnToAddress1"
        label="Address 1"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        required
      />
      <Field
        component={FormControl.Input}
        name="returnToAddress2"
        label="Address 2"
        helperText="max 35 characters"
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        maxLength={35}
      />
      <Field
        component={FormControl.Input}
        name="returnToCity"
        label="City"
        helperText="max 35 characters"
        maxLength={35}
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        required
      />
      <Field
        component={FormControl.Input}
        name="returnToCounty"
        label="County / State"
        helperText="max 35 characters"
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        maxLength={35}
      />
      <Field
        component={DoubleAstericksInput}
        name="returnToContactName"
        label="Contact Name"
        helperText="max 35 characters"
        style={{ textTransform: "uppercase" }}
        textTransform="uppercase"
        maxLength={35}
        required={reqContactName}
      />
    </Step>
  );
};

const formSelector = formValueSelector(RETURN_FORM);

ReturnTo = connect(state => {
  const organisationName = formSelector(
    state,
    CONTACT_FIELDS.RETURN_TO_ORGANISATION
  );
  const contactName = formSelector(
    state,
    CONTACT_FIELDS.RETURN_TO_CONTACT_NAME
  );
  return {
    requiredContactFields: {
      reqOrganisationName: !contactName || organisationName?.length,
      reqContactName: !organisationName || contactName?.length,
    },
  };
})(ReturnTo);

ReturnTo.propTypes = {
  updateFormField: PropTypes.func,
  dispatch: PropTypes.func,
  withAutocomplete: PropTypes.bool,
  requiredContactFields: PropTypes.object,
};

export { ReturnTo };
