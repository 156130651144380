import api from "./api";

export const fetchPreferences = () =>
  api.get({
    path: "/ums/user/preferences",
  });

export const fetchCustomer = () =>
  api.get({
    path: "/ums/customer",
  });

export const fetchAdvanceConfiguration = () =>
  api.get({
    path: "/ums/user/advancedConfiguration",
  });

export const fetchShippingSettings = () =>
  api.get({
    path: "/ums/user/shippingSettings",
  });

export const fetchCustomerAddress = () =>
  api.get({
    path: "/ums/user/customerAddress",
  });

export const fetchSecuritySettings = () =>
  api.get({
    path: "/ums/user/securitySettings",
  });
