import { get } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import { Delimiter } from "../../../../../components/Delimiter/Delimiter";
import styles from "./EstimatedDateParcelInstructions.module.scss";

export const EstimatedDateParcelInstructions = ({ parcel }) => {
  const { parcelStatusType, deliveryDepot, estimatedInstructions } = parcel;
  const routeCode = get(deliveryDepot, "route.routeCode");

  return (
    parcelStatusType === PARCEL_STATUS.ESTIMATED_DATE && (
      <>
        <Container>
          <Row>
            <Col sm={12}>
              <div className={styles.instructions}>
                {!routeCode && estimatedInstructions}
              </div>
            </Col>
          </Row>
        </Container>
        <Delimiter />
      </>
    )
  );
};

EstimatedDateParcelInstructions.propTypes = {
  parcel: PropTypes.object,
};
