import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import { Button, Card, Step } from "@dpdgroupuk/mydpd-ui";

import Box from "../../assets/images/sad-box.png";

export const Error = ({ title, message, buttonText, onClickHandler }) => {
  const footer = <Button onClick={onClickHandler}>{buttonText}</Button>;

  return (
    <Card.Stack className="text-center">
      <Col md={12}>
        <Step title={title} footer={footer}>
          <Container fluid>
            <Row>
              <Col md={12}>
                <img className="error-img" src={Box} alt={message} />
              </Col>
              <Col md={12}>
                <span className="error-message">{message}</span>
              </Col>
            </Row>
          </Container>
        </Step>
      </Col>
    </Card.Stack>
  );
};

Error.propTypes = {
  title: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

Error.defaultProps = {
  title: "Error",
  onClickHandler: () => null,
};
