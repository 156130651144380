import PropTypes from "prop-types";

import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import styles from "../ParcelAdditionalStatus.module.scss";

export const ParcelStatusIcon = ({ icon: Icon }) => {
  const theme = useMyDpdTheme();

  return (
    <span className={styles.issue_logo}>
      <Icon fill={theme.palette.brand} />
    </span>
  );
};

ParcelStatusIcon.propTypes = {
  icon: PropTypes.func,
};
