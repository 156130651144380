import PropTypes from "prop-types";

export default function Unexpected({ fill = "#DC0032" }) {
  return (
    <svg viewBox="0 0 32 32" fill={fill} width="32px" height="32px">
      <path d="M19.36 12.768h-1.504v6.048l5.248 3.2 0.8-1.312-4.544-2.72zM18.88 7.712c-0.004 0-0.009 0-0.014 0-1.093 0-2.145 0.176-3.129 0.5l0.071-0.020c-1.035-2.391-3.374-4.033-6.096-4.033-3.658 0-6.624 2.966-6.624 6.624 0 3.337 2.467 6.097 5.677 6.557l0.035 0.004q0 0.224 0 0.448c0 5.536 4.544 10.080 10.080 10.080s10.048-4.544 10.048-10.080-4.512-10.080-10.048-10.080zM18.88 25.856c-4.448 0-8.064-3.648-8.064-8.064 0-0.16 0-0.32 0.032-0.512 3.13-0.568 5.472-3.272 5.472-6.523 0-0.002 0-0.003 0-0.005v0c0-0.192 0-0.384-0.032-0.576 0.771-0.279 1.662-0.442 2.589-0.448h0.003c4.416 0 8.032 3.648 8.032 8.064s-3.616 8.064-8.032 8.064zM8.928 14.4c0-0.48 0.32-0.8 0.768-0.8 0.48 0 0.768 0.32 0.768 0.8 0 0.448-0.288 0.8-0.768 0.8-0.448 0-0.768-0.352-0.768-0.8zM9.248 12.544l-0.16-6.208h1.216l-0.16 6.208z" />
    </svg>
  );
}

Unexpected.propTypes = {
  fill: PropTypes.string,
};
