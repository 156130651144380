import api from "./api";

export const fetchParcelReturns = query =>
  api.get({
    path: "/v1/parcel/returns",
    query,
  });

export const fetchParcelCodes = query =>
  api.get({
    path: "/v1/parcelcode",
    query,
  });

export const fetchParcelByParcelCode = query =>
  api.get({
    path: "/v1/parcels",
    query,
  });

export const fetchReturnsCsv = query =>
  api.get({
    path: "/v1/returns/csv",
    query,
  });

export const fetchFilterData = query =>
  api.get({
    path: "/v1/filter",
    query,
  });

export const fetchFilterReturns = query =>
  api.get({
    path: "/v1/filters/returns",
    query,
  });

export const fetchReturnActions = parcelCode =>
  api.get({
    path: `/v1/returns/${parcelCode}/actions`,
  });
