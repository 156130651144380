import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import { AddressBookReducer } from "./addressBook";
import { CreateReturnReducer } from "./createReturn";
import { DashboardReducer } from "./dashboard";
import { ParcelViewReducer } from "./parcelView";
import { ReturnReducer } from "./returns";
import { SearchReducer } from "./search";
import { UmsReducer } from "./ums";

export default combineReducers({
  form: reduxFormReducer,
  ui: combineReducers({
    createReturn: CreateReturnReducer,
    dashboard: DashboardReducer,
    parcelView: ParcelViewReducer,
    search: SearchReducer,
  }),
  returns: ReturnReducer,
  app: combineAppReducer({
    ums: UmsReducer,
  }),
  addressBook: AddressBookReducer,
});
