import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("ADDRESS_BOOK", {
  FETCH_ADDRESS_BOOK: createAsyncActionTypes("FETCH_ADDRESS_BOOK"),
  FETCH_ADDRESS_BOOK_BY_ID: createAsyncActionTypes("FETCH_ADDRESS_BOOK_BY_ID"),
  FETCH_DEFAULT_ADDRESS: createAsyncActionTypes("FETCH_DEFAULT_ADDRESS"),
  SET_RETURN_FROM_SELECTED_ADDRESS: "SET_RETURN_FROM_SELECTED_ADDRESS",
  SET_RETURN_TO_SELECTED_ADDRESS: "SET_RETURN_TO_SELECTED_ADDRESS",
  CLEAR_CREATE_RETURN_SELECTED_ADDRESSES:
    "CLEAR_CREATE_RETURN_SELECTED_ADDRESSES",
  CLEAR_ADDRESS_BOOK: "CLEAR_ADDRESS_BOOK",
});
