import { useEffect, useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { change, untouch } from "redux-form";

import { Button, Main } from "@dpdgroupuk/mydpd-ui";

import { createReturn, getLabelAxios } from "../../../../../apis/createReturn";
import {
  CREATE_RETURN_FIELDS,
  RETURN_FORM,
} from "../../../../../constants/forms";
import { printLabel } from "../../../../../helpers/printLabel";
import { AddressBookActions } from "../../../../../redux";
import { getSelectedCountry } from "../../../../../redux/selectors";
import { formatReturn } from "../../../../../utils/return";

export const ReturnFormButtonToolbar = ({
  dispatch,
  overlay,
  banner,
  snackbar,
}) => {
  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [disableEmailButton, setDisableEmailButton] = useState(true);
  const { user } = useSelector(state => state.app.auth);
  const selectedCountry = useSelector(state => getSelectedCountry(state));
  const { values, syncErrors, initial } = useSelector(
    state => state.form.return
  );
  const { returnToSelectedAddress, defaultAddress } = useSelector(
    state => state.addressBook
  );

  useEffect(() => {
    if (isEmpty(syncErrors)) {
      setDisablePrintButton(false);
      setDisableEmailButton(false);
    } else {
      setDisablePrintButton(true);
      setDisableEmailButton(true);
    }

    // There must be an email to enable the email now button
    if (!("returnFromEmail" in values)) {
      setDisableEmailButton(true);
    }
  }, [syncErrors, values]);

  const resetReturnFrom = () => {
    dispatch(AddressBookActions.clearCreateReturnSelectedAddresses());
    CREATE_RETURN_FIELDS.forEach(field => {
      if (field.includes("returnFrom") || field.includes("returnReference")) {
        if (!isEmpty(initial) && field in initial) {
          dispatch(change(RETURN_FORM, field, initial[field]));
        } else {
          dispatch(change(RETURN_FORM, field, ""));
        }
      }
      dispatch(untouch(RETURN_FORM, field));
    });
  };

  const resetReturnTo = () => {
    if (isEmpty(defaultAddress)) {
      dispatch(AddressBookActions.clearCreateReturnSelectedAddresses());
      CREATE_RETURN_FIELDS.forEach(field => {
        if (field.includes("returnTo")) {
          if (!returnToSelectedAddress.isDefault) {
            dispatch(change(RETURN_FORM, field, ""));
          }
        }
        dispatch(untouch(RETURN_FORM, field));
      });
    }
  };

  const onClickCopyNumber = shipmentId => {
    return navigator.clipboard.writeText(shipmentId).then(
      snackbar.showSuccess({
        message: "Consignment number copied to clipboard",
      })
    );
  };

  const handleCreateReturn = async (sendEmail = false) => {
    overlay.show();
    banner.removeAll();
    try {
      const profileCode = values.profileCode;
      const businessId = user.businessId;

      const formattedReturn = formatReturn(
        values,
        profileCode,
        selectedCountry.countryCode,
        businessId
      );

      const { data } = await createReturn(formattedReturn);

      const consignmentNum = data.consignments[0].consignmentNumber;
      const shipmentId = data.shipmentId;

      const label = await getLabelAxios(shipmentId, sendEmail, businessId);

      overlay.hide();
      window.scrollTo(0, 0);
      banner.removeAll();
      banner.showByType("success", {
        message: `Successfully created return. Your consignment number is ${consignmentNum}`,
        closable: true,
        showIcon: true,
        actions: [
          {
            text: "Copy number",
            handleClick: () => onClickCopyNumber(consignmentNum),
          },
        ],
      });

      if (!sendEmail) {
        await printLabel(label);
      }
      resetReturnFrom();
      resetReturnTo();
    } catch (error) {
      overlay.hide();
      window.scrollTo(0, 0);
      banner.removeAll();
      banner.showByType("alert", {
        message: "Unable to create return",
        closable: true,
        showIcon: true,
        actions: [],
      });
    }
  };

  const resetReturnFromSection = () => {
    dispatch(AddressBookActions.clearCreateReturnSelectedAddresses());

    CREATE_RETURN_FIELDS.forEach(field => {
      if (!isEmpty(initial) && field in initial) {
        dispatch(change(RETURN_FORM, field, initial[field]));
      } else {
        dispatch(change(RETURN_FORM, field, ""));
      }

      dispatch(untouch(RETURN_FORM, field));
    });
  };

  return (
    <Main.Footer className="dark">
      <Button.Toolbar>
        <Button variant="light" onClick={resetReturnFromSection}>
          Clear
        </Button>
        <Button
          variant="light"
          disabled={disableEmailButton}
          onClick={() => handleCreateReturn(true)}
        >
          Email Now
        </Button>
        <Button
          variant="light"
          disabled={disablePrintButton}
          onClick={() => handleCreateReturn()}
        >
          Print Now
        </Button>
      </Button.Toolbar>
    </Main.Footer>
  );
};

ReturnFormButtonToolbar.propTypes = {
  dispatch: PropTypes.func,
  overlay: PropTypes.object,
  banner: PropTypes.object,
  snackbar: PropTypes.object,
};
