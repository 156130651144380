import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { Step } from "@dpdgroupuk/mydpd-ui";

import ResultsTable from "../ResultsTable/ResultsTable";

export const Results = ({ pagination }) => {
  return (
    <Col md={12} className="resultsStep">
      <Step title="Results" helpModalTitle={"Shop Returns Search"}>
        <ResultsTable pagination={pagination} />
      </Step>
    </Col>
  );
};

Results.propTypes = {
  pagination: PropTypes.object,
};
