import PropTypes from "prop-types";

import { Tile, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

const OptionItem = ({ icon: Icon, title, onClick }) => {
  const theme = useMyDpdTheme();
  return (
    <Tile title={title} onClick={() => onClick()}>
      {Icon && <Icon alt={title} fill={theme.palette.brand} />}
    </Tile>
  );
};

OptionItem.propTypes = {
  icon: PropTypes.func,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default OptionItem;
