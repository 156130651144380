import { useEffect, useState } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { withProps } from "recompose";
import { compose } from "redux";

import { Button, DndTable, Table, withOverlay } from "@dpdgroupuk/mydpd-ui";

import { PopupModal } from "../../../../../components/PopupModal/PopupModal";
import { CSV_MAX_ROW_COUNT } from "../../../../../constants/forms";
import { ReturnSelectors, SearchActions } from "../../../../../redux";
import { SEARCH } from "../../../../../router/constants";
import { formatAddress } from "../../../../../utils/format";
import { getExportCSVLink, isValidURL } from "../../../../../utils/query";
import styles from "./ResultsTable.module.scss";

const TableColumnTitle = ({ title }) => {
  return <div className={styles.resultsTableColumnTitle}>{title}</div>;
};

const ResultsTable = ({ fetchReturnsCsv, overlay, pagination }) => {
  const [showModal, setShowModal] = useState(false);
  const [csvLink, setCsvLink] = useState("");
  const [tableData, setTableData] = useState([]);
  const [pageSize] = useState(25);
  const { findByCode, findByTotal } = useSelector(state => state.returns);
  const parcels = useSelector(state => ReturnSelectors.getParcels(state));
  const history = useHistory();

  useEffect(() => {
    const updatedTableData = [];
    for (const parcel of parcels) {
      const parcelObject = {
        createdDate: parcel?.createdDate,
        returnsReference: parcel?.returnsReference,
        account: parcel?.accountCode,
        parcelNumber: parcel?.parcelNumber,
        status: parcel?.parcelStatus,
        fromAddress: formatAddress(parcel?.collectionDetails.collectionAddress),
        toAddress: formatAddress(parcel?.deliveryDetails.deliveryAddress),
        emailPrint: parcel?.emailPrint,
        parcelCode: parcel?.parcelCode,
      };
      updatedTableData.push(parcelObject);
    }

    setTableData(updatedTableData);
  }, [parcels]);

  useEffect(() => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    if (!currentUrlParams.get("page")) {
      const query = `${history.location.search}&page=${pagination.page}`;
      history.replace({ search: query });
    }
    currentUrlParams.set("page", pagination.page);

    history.push({ search: currentUrlParams.toString() });
  }, [pagination.page]);

  const tableColumnStyle = {
    width: "120px",
  };

  const table = {
    columns: [
      {
        text: "Created Date",
        dataIndex: "createdDate",
        style: { width: "85px" },
      },
      {
        text: "Return Reference",
        dataIndex: "returnsReference",
        style: { width: "90px" },
      },
      {
        text: "Account",
        dataIndex: "account",
        style: { width: "75px" },
      },
      {
        text: "Parcel Number",
        dataIndex: "parcelNumber",
        style: tableColumnStyle,
      },
      {
        text: "Status",
        dataIndex: "status",
        style: { width: "90px" },
      },
      {
        text: "From Address",
        dataIndex: "fromAddress",
        style: tableColumnStyle,
      },
      {
        text: "To Address",
        dataIndex: "toAddress",
        style: tableColumnStyle,
      },
      {
        text: "Email/Print",
        dataIndex: "emailPrint",
        style: { width: "85px" },
      },
    ],
  };

  const handleExtractClick = async () => {
    if (findByTotal < CSV_MAX_ROW_COUNT) {
      return;
    }

    overlay.show();
    setCsvLink("");

    const { data } = await fetchReturnsCsv({ key: findByCode });
    overlay.hide();

    if (!isEmpty(data) && isValidURL(data)) {
      setCsvLink(data);
      setShowModal(true);
    }
  };

  const handleRowClick = (e, parcelCode) => {
    history.push(`${SEARCH}/${parcelCode}`, { from: "search" });
  };

  return (
    <div className="results-table">
      <PopupModal showModal={showModal} setShowModal={setShowModal}>
        <span style={{ margin: "45px" }}>
          Please click the below link to download your CSV file
        </span>
        <div style={{ margin: "auto", width: "30%", paddingTop: "10px" }}>
          <a href={csvLink} download>
            Download File
          </a>
        </div>
      </PopupModal>
      <Table
        id="table"
        borderless
        data={tableData}
        columns={table.columns}
        onClickRow={(e, { parcelCode }) => handleRowClick(e, parcelCode)}
      >
        <Container fluid>
          <DndTable.DndPaginator
            page={pagination.page}
            totalCount={findByTotal}
            pageSize={pageSize}
            // statusText={S.SHOWING}
            onNext={() => {
              overlay.hide();
              pagination.setStartPos(pagination.startPos + pageSize);
              pagination.setEndPos(pagination.endPos + pageSize);
              pagination.setPage(pagination.page + 1);
              overlay.show();
            }}
            onPrevious={() => {
              overlay.hide();
              pagination.setStartPos(pagination.startPos - pageSize);
              pagination.setEndPos(pagination.endPos - pageSize);
              pagination.setPage(pagination.page - 1);
              overlay.show();
            }}
            onLast={() => {
              overlay.hide();
              pagination.setStartPos(findByTotal - pageSize + 1);
              pagination.setEndPos(findByTotal);
              pagination.setPage(Math.ceil(findByTotal / pageSize));
              overlay.show();
            }}
            onFirst={() => {
              overlay.hide();
              pagination.setStartPos(1);
              pagination.setEndPos(pageSize);
              pagination.setPage(1);
              overlay.show();
            }}
          />
          <Row className="mt-4 mb-2 ml-4 justify-content-end">
            <Col xs="auto">
              <Button
                href={
                  findByTotal < CSV_MAX_ROW_COUNT &&
                  getExportCSVLink(findByCode)
                }
                className="button"
                onClick={handleExtractClick}
              >
                Extract
              </Button>
            </Col>
          </Row>
        </Container>
      </Table>
    </div>
  );
};

TableColumnTitle.propTypes = {
  title: PropTypes.string,
};

ResultsTable.propTypes = {
  fetchReturnsCsv: PropTypes.func,
  overlay: PropTypes.object,
  pagination: PropTypes.object,
};

export default compose(
  withOverlay,
  connect(null, dispatch => ({
    fetchReturnsCsv: params => dispatch(SearchActions.fetchReturnsCsv(params)),
  })),
  withProps(({ overlay }) => ({
    overlay,
  }))
)(ResultsTable);
