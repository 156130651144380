import { useState } from "react";

import { isEmpty, isNil, omitBy } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { lifecycle } from "recompose";
import { compose } from "redux";

import { AuthUtil, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import { Card, Legend, Main, withLoader } from "@dpdgroupuk/mydpd-ui";

import { Error } from "../../../components/Error/Error";
import {
  AuthSelectors,
  ReturnActions,
  ReturnSelectors,
  SearchActions,
  SearchSelectors,
} from "../../../redux";
import { SEARCH } from "../../../router/constants";
import {
  getFilters,
  getPageQuery,
  getPageToEndPos,
  getPageToStartPos,
  getSearchQuery,
} from "../../../utils/query";
import FilterResults from "./components/FilterResults/FilterResults";
import { Results } from "./components/Results/Results";

const SearchResults = ({ authUser, pagination }) => {
  const [startPos, setStartPos] = useState(pagination.startPos);
  const [endPos, setEndPos] = useState(pagination.endPos);
  const [page, setPage] = useState(pagination.page);
  const [error, setError] = useState(false);

  return (
    <Main.Body>
      {error ? (
        <Error message="Not Found" buttonText="Retry" />
      ) : (
        <>
          <Legend
            leftMessage="Shop Returns Search"
            rightMessage={`${AuthUtil.getDisplayName(
              authUser?.user
            )} (Account: ${authUser?.user?.account})`}
          />
          <Card.Stack fluid>
            <FilterResults
              pagination={{
                startPos,
                setStartPos,
                endPos,
                setEndPos,
                reset: (start, end) => {
                  setStartPos(start);
                  setEndPos(end);
                },
                page,
                setPage,
              }}
              error={error}
              setError={setError}
            />
            <Results
              pagination={{
                startPos,
                setStartPos,
                endPos,
                setEndPos,
                page,
                setPage,
              }}
            />
          </Card.Stack>
        </>
      )}
    </Main.Body>
  );
};

SearchResults.propTypes = {
  authUser: PropTypes.object,
  pagination: PropTypes.object,
};

export default compose(
  withAppUserPreferences,
  connect(
    state => ({
      user: AuthSelectors.getAuthUser(state),
      businessUnit: AuthSelectors.getBusinessUnit(state),
      account: ReturnSelectors.getAccount(state),
      findByCode: ReturnSelectors.getFindbyCode(state),
      findByTotal: ReturnSelectors.getFindbyTotal(state),
      pagination: SearchSelectors.getPagination(state),
    }),
    dispatch => ({
      fetchParcelReturns: params =>
        dispatch(ReturnActions.fetchParcelReturns(params)),
      fetchFilterData: params =>
        dispatch(SearchActions.fetchFilterData(params)),
      fetchParcelCodes: params =>
        dispatch(ReturnActions.fetchParcelCodes(params)),
      fetchParcelByParcelCode: params =>
        dispatch(ReturnActions.fetchParcelByParcelCode(params)),
      resetReturns: () => dispatch(ReturnActions.resetReturns()),
      setAccount: params => dispatch(ReturnActions.setAccount(params)),
      setSearchFindbyCode: params =>
        dispatch(ReturnActions.setSearchFindbyCode(params)),
      setFindby: (code, total) =>
        dispatch(ReturnActions.setFindby(code, total)),
      fetchFilterReturns: params =>
        dispatch(SearchActions.fetchFilterReturns(params)),
      setFilterPagination: params =>
        dispatch(SearchActions.setFilterPagination(params)),
      setFilterValues: params =>
        dispatch(SearchActions.setFilterValues(params)),
      fetchReturnsDashboardFindbyCode: params =>
        dispatch(ReturnActions.fetchReturnsDashboardFindbyCode(params)),
    })
  ),
  withLoader({
    loadFn: async ({
      fetchParcelReturns,
      fetchFilterData,
      fetchParcelCodes,
      fetchParcelByParcelCode,
      setAccount,
      setSearchFindbyCode,
      setFindby,
      setFilterPagination,
      setFilterValues,
      fetchFilterReturns,
      fetchReturnsDashboardFindbyCode,
      account,
      businessUnit,
      findByCode,
      findByTotal,
      location,
      history,
      pagination,
    }) => {
      const query = getSearchQuery(location);
      const { page } = getPageQuery(location);
      let { startPos, endPos } = pagination;
      let parcel;
      const fromUrl = "pot" in query ? "dashboard" : "search";

      // If user navigates from dashboard using URL rather than through UI
      if (isEmpty(findByCode) && fromUrl === "dashboard") {
        const request = {
          accountNumber: !isEmpty(account) ? account : query.account,
          pot: query.pot,
          businessUnit,
        };

        if (request.pot !== "atShop") {
          request.estimatedDeliveryDate = query.deliveryDate;
        }

        const dashboardFindbyCode = await fetchReturnsDashboardFindbyCode(
          request
        );

        if (!isEmpty(dashboardFindbyCode.findByCode)) {
          findByCode = dashboardFindbyCode.findByCode;
          findByTotal = dashboardFindbyCode.findByTotal;
          await setSearchFindbyCode(dashboardFindbyCode.findByCode);
        }
      }

      // If user navigates to search results using URL rather than searching through UI
      if (isEmpty(findByCode) || (isEmpty(parcel) && fromUrl === "search")) {
        await setAccount(query.account);
        parcel = await fetchParcelReturns(omitBy(query, isNil));
        await setSearchFindbyCode(parcel.findby_code);
        findByCode = parcel.findby_code;
        findByTotal = parcel.findby_total;
      }

      if (!isEmpty(page)) {
        // Use parcel findby_total as there will be no value in redux state
        startPos = getPageToStartPos(page, findByTotal);
        endPos = getPageToEndPos(page, findByTotal);
        await setFilterPagination({ page: parseInt(page), startPos, endPos });
      }

      const { parcelCodes } = await fetchParcelCodes({
        findByCode: !isEmpty(findByCode) ? findByCode : parcel.findby_code,
        startPos,
        endPos,
      });

      if (findByTotal === 1) {
        history.push(`${SEARCH}/${parcelCodes[0]}`, { from: "search" });
      } else {
        await fetchParcelByParcelCode({
          parcelCode: parcelCodes,
        });

        const filters = getFilters(location);

        if (filters) {
          await setFilterValues(filters);

          const selectedAccount = !isEmpty(account) ? account : query.account;

          const filterReq = {
            findByCode: !isEmpty(findByCode) ? findByCode : parcel.findby_code,
            businessUnit: !isEmpty(query.businessUnit)
              ? query.businessUnit
              : businessUnit,
            ...(!Array.isArray(selectedAccount) && {
              accountCode: selectedAccount,
            }),
          };

          if (location.search.includes("shipmentCreateDate")) {
            const { findbyCode, findByTotal } = await fetchFilterReturns(
              filterReq
            );
            await setFindby(findbyCode, findByTotal);
          } else {
            const { findbyCode, findByTotal } = await fetchFilterReturns({
              ...filters,
              ...filterReq,
            });

            await setFindby(findbyCode, findByTotal);
          }
        }

        // Use account number from URL if user navigates to search results using URL
        await fetchFilterData({
          key: "1",
          businessUnit,
          excludePseudoDepots: true,
          excludeHubs: true,
          account: !isEmpty(account) ? account : query.account,
        });
      }
    },
  }),
  lifecycle({
    componentWillUnmount() {
      this.props.resetReturns();
    },
  })
)(SearchResults);
