import ActionTypes from "./actionTypes";

export const initialState = {
  stats: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RETURNS_DASHBOARD.SUCCESS: {
      return {
        ...state,
        stats: action.payload,
      };
    }
    case ActionTypes.GET_RETURNS_DASHBOARD.FAILURE: {
      return {
        ...state,
        stats: initialState.stats,
      };
    }
    case ActionTypes.RESET_DASHBOARD: {
      return {
        ...state,
        stats: initialState.stats,
      };
    }
    default:
      return state;
  }
};
