import { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";

import { getVehicleImage } from "../../../../../../constants/drivers";
import styles from "./Progress.module.scss";

export const Progress = ({
  currentPosition,
  startPosition,
  windowStartTime,
  windowEndTime,
  vehicleTypeCode,
}) => {
  const vehicleSrc = useMemo(() => {
    return getVehicleImage(vehicleTypeCode);
  }, [vehicleTypeCode]);

  return (
    <div className={styles.container}>
      <img
        id="Progress-1"
        style={{ left: `${currentPosition}%` }}
        className={styles.track_img}
        src={vehicleSrc}
        alt="truck"
      />
      <ProgressBar className={styles.root}>
        <ProgressBar className={styles.bar1} now={currentPosition} key={1} />
        <ProgressBar
          className={styles.bar2}
          now={startPosition - currentPosition}
          key={2}
        />
        <ProgressBar
          className={styles.bar3}
          now={100 - startPosition}
          key={3}
        />
      </ProgressBar>
      <span
        style={{ left: `${startPosition}%` }}
        className={classNames(
          styles.time,
          styles.time_start,
          startPosition > 90 && styles.time_start_shift
        )}
      >
        {windowStartTime}
      </span>
      <span className={classNames(styles.time, styles.time_end)}>
        {windowEndTime}
      </span>
    </div>
  );
};

Progress.propTypes = {
  parcel: PropTypes.object,
  currentPosition: PropTypes.number.isRequired,
  startPosition: PropTypes.number.isRequired,
  windowStartTime: PropTypes.string.isRequired,
  windowEndTime: PropTypes.string.isRequired,
  vehicleTypeCode: PropTypes.string,
};
