import fp from "lodash/fp";

export const addSpacesToCamelCase = str => {
  str.replace(/([A-Z])/g, " $1").replace(/^./, str => {
    return str.toUpperCase();
  });
};

export const formatMessage = (template, ...args) =>
  template.replace(/{(\d)}/g, (s, num) => args[num]);

export const onlyStrings = fp.compose(
  fp.filter(fp.isString),
  fp.filter(fp.negate(fp.isEmpty))
);

export const joinStringsWithComma = fp.compose(fp.join(", "), onlyStrings);

export const replaceLinebreak = (str, replaceWith = " ") =>
  str.replace(/(?:\r\n|\r|\n)/g, replaceWith);
