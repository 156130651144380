import PropTypes from "prop-types";

import { Table } from "@dpdgroupuk/mydpd-ui";

const columns = [
  {
    text: "Parcel No",
    dataIndex: "relatedParcelCode",
    style: {
      width: "100px",
    },
  },
  {
    text: "Status",
    dataIndex: "parcelStatus",
    style: {
      width: "155px",
    },
  },
];

export const Consignment = ({ data }) => {
  return <Table columns={columns} data={data} bordered />;
};

Consignment.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
