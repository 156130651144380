import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { dashboard, search } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchParcelReturns = createAsyncAction(
  query => search.fetchParcelReturns(query).then(res => res),
  ActionTypes.GET_PARCEL_RETURNS
);

export const fetchParcelCodes = createAsyncAction(
  query => search.fetchParcelCodes(query).then(res => res),
  ActionTypes.GET_PARCEL_CODES
);

export const fetchParcelByParcelCode = createAsyncAction(
  query => search.fetchParcelByParcelCode(query).then(res => res),
  ActionTypes.GET_PARCEL
);

export const fetchReturnsDashboardFindbyCode = createAsyncAction(
  query => dashboard.fetchReturnsDashboardFindbyCode(query).then(res => res),
  ActionTypes.GET_RETURNS_DASHBOARD_FINDBY_CODE
);

export const resetReturns = () => ({
  type: ActionTypes.RESET_RETURNS,
});

export const setAccount = account => ({
  type: ActionTypes.SET_ACCOUNT,
  account,
});

export const setFindby = (code, total) => ({
  type: ActionTypes.SET_FINDBY,
  code,
  total,
});

export const setSearchFindbyCode = code => ({
  type: ActionTypes.SET_SEARCH_FINDBY_CODE,
  code,
});
