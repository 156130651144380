import { getApiOrigin } from "../utils/config";
import * as addressBook from "./addressBook";
import apisClient from "./api";
import * as createReturn from "./createReturn";
import * as dashboard from "./dashboard";
import * as parcelView from "./parcelView";
import * as postcodeFinder from "./postcodeFinder";
import * as search from "./search";
import * as ums from "./ums";

const APIS_ORIGIN_URL = getApiOrigin();

export {
  APIS_ORIGIN_URL,
  apisClient,
  addressBook,
  createReturn,
  dashboard,
  parcelView,
  postcodeFinder,
  search,
  ums,
};
