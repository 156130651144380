import { useCallback, useEffect, useRef } from "react";

import PropTypes from "prop-types";

import { Form } from "@dpdgroupuk/mydpd-ui";
import "react-bootstrap-typeahead/css/Typeahead.css";

export const AutocompleteDropdown = ({
  label,
  helperText,
  input,
  meta,
  disabled,
  readonly,
  required,
  id,
  optionLabelMapper,
  labelKey,
  onSelectionChange,
  maxLength,
  onChange,
  type,
  minLength,
  options,
}) => {
  const inputValueRef = useRef(input.value);

  useEffect(() => {
    inputValueRef.current = input.value;
  }, [input.value]);

  const handleInputChange = useCallback(
    value => {
      input.onChange(value);

      if (onChange) {
        onChange(value);
      }
    },
    [input, onChange]
  );

  const handleSelectionChange = useCallback(
    values => {
      onSelectionChange(values);
    },
    [onSelectionChange]
  );

  return (
    <Form.Autocomplete
      withoutHint
      label={label}
      helperText={helperText}
      meta={meta}
      input={{
        ...input,
        onBlur: () => {
          input.onBlur(inputValueRef.current.toUpperCase());
        },
      }}
      required={required}
      disabled={disabled}
      readonly={readonly}
      id={id}
      onInputChange={handleInputChange}
      options={options}
      onSelectionChange={handleSelectionChange}
      labelKey={labelKey}
      optionLabelMapper={optionLabelMapper}
      maxLength={maxLength}
      type={type}
      minLength={minLength}
      async={false}
      paginationText={"Show more"}
      maxResults={15}
    />
  );
};

AutocompleteDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  label: PropTypes.string,
  helperText: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  required: PropTypes.bool,
  optionLabelMapper: PropTypes.func,
  labelKey: PropTypes.oneOfType(PropTypes.string, PropTypes.func),
  onSelectionChange: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  type: PropTypes.string,
  options: PropTypes.array,
};
