import { get } from "lodash";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import { APIS_ORIGIN_URL } from "../apis";
import { EstimatedDateParcelView } from "../pages/ShopReturns/ParcelView/components/FocusBox/EstimatedDateParcelView";
import { GeneralParcelView } from "../pages/ShopReturns/ParcelView/components/FocusBox/GeneralParcelView";
import { IssueParcelView } from "../pages/ShopReturns/ParcelView/components/FocusBox/IssueParcelView";
import OutForDeliveryParcelView from "../pages/ShopReturns/ParcelView/components/FocusBox/OutForDeliveryParcelView";
import { RFIReasonParcelView } from "../pages/ShopReturns/ParcelView/components/FocusBox/RFIReasonParcelView";
import {
  calculatePositionInPeriod,
  chooseSmallerDate,
  formatInternalTime,
  formatTime,
  getCurrentDateTime,
} from "../utils/date";

const DEFAULT_SIZE = [940, 432];
const DEFAULT_ZOOM = 16;

const getProgressStatus = (parcel, route, driver) => {
  const driverName =
    get(route, "driverDisplayName") || get(driver, "driverName");
  const totalCompletedStops =
    route.completedCollectionStops + route.completedDeliveryStops;

  const myStop = parcel.deliveryDetails.route.stop.stopNumber || 0;

  const currentStop =
    totalCompletedStops >= myStop && myStop > 0
      ? myStop - 1
      : totalCompletedStops;

  return `${
    driverName || "Your driver"
  } is currently making stop number ${currentStop}, this delivery is stop number ${myStop}`;
};

export const getParcelProgressData = (parcel, route, driver) => {
  const deliveryWindowFrom = get(
    parcel,
    "deliveryDetails.route.stop.deliveryWindowFrom",
    ""
  );
  const deliveryWindowTo = get(
    parcel,
    "deliveryDetails.route.stop.deliveryWindowTo",
    ""
  );
  const driverName =
    get(route, "driverDisplayName") || get(driver, "driverName");

  const progressStatus = getProgressStatus(parcel, route, driver);

  const routeTimeInSeconds = route.routeTime;
  const routeDateTime = routeTimeInSeconds
    ? formatInternalTime(routeTimeInSeconds)
    : null;

  const driverStartTime = chooseSmallerDate(routeDateTime, deliveryWindowFrom);

  let currentPosition = calculatePositionInPeriod(
    getCurrentDateTime(),
    driverStartTime,
    deliveryWindowTo
  );
  // add min value as 5 to avoid negative values in progress
  currentPosition = Math.max(currentPosition, 5);

  const startPosition = calculatePositionInPeriod(
    deliveryWindowFrom,
    driverStartTime,
    deliveryWindowTo
  );

  const progressData = {
    showProgressBar: currentPosition < 100,
    progressStatus,
    currentPosition,
    startPosition,
    windowStartTime: formatTime(deliveryWindowFrom),
    windowEndTime: formatTime(deliveryWindowTo),
    vehicleTypeCode: get(driver, "vehicleTypeCode"),
  };

  return {
    code: parcel.parcelCode,
    customerImageLogo: parcel.customerImageLogo,
    deliveryNumber: `Parcel No. ${parcel.parcelNumber}`,
    statusHtml: parcel.parcelStatusHtml,
    query: "parcelCode",
    electricMessage: `${driverName} proudly\ndrives a 100%\nelectric ${driver.vehicleTypeName}`,
    isVehicleElectric: !!get(parcel, "deliveryDetails.onRouteElectric"),
    showIdMessage: !!get(parcel, "parcelStatusInfo"),
    parcelStatusInfoMessage: "Your driver will need to see photo ID!",
    progressData,
  };
};

export const getParcelComponent = parcelStatusType => {
  switch (parcelStatusType) {
    case PARCEL_STATUS.ISSUE:
      return IssueParcelView;
    case PARCEL_STATUS.OUT_FOR_DELIVERY:
      return OutForDeliveryParcelView;
    case PARCEL_STATUS.ESTIMATED_DATE:
      return EstimatedDateParcelView;
    case PARCEL_STATUS.RFI_REASON:
      return RFIReasonParcelView;
    default:
      return GeneralParcelView;
  }
};

export const getEventLocationMapUrl = (
  { latitude, longitude },
  [width, height] = DEFAULT_SIZE
) =>
  `${APIS_ORIGIN_URL}/map/coordinates?latLong=${[
    latitude,
    longitude,
  ].toString()}&size=${width}*${height}&zoom=${DEFAULT_ZOOM}`;

export const getDriverImageUrl = (imageId, imageType) =>
  `${APIS_ORIGIN_URL}/images/${imageId}?&imageType=${imageType}`;

export const getImageUrl = (imageKey, imageType) =>
  `${APIS_ORIGIN_URL}/images/${imageKey}?imageType=${imageType}`;

export const getMapUrl = (pacelCode, [width, height] = DEFAULT_SIZE) =>
  `${APIS_ORIGIN_URL}/map/route?size=${width}*${height}&parcelCode=${pacelCode}`;
