import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("SINGLE_RETURN", {
  GET_PARCEL: createAsyncActionTypes("GET_PARCEL"),
  GET_RETURN_ACTIONS: createAsyncActionTypes("GET_RETURN_ACTIONS"),
  GET_PARCEL_EVENTS: createAsyncActionTypes("GET_PARCEL_EVENTS"),
  GET_RELATED_PARCELS: createAsyncActionTypes("GET_RELATED_PARCELS"),
  GET_ROUTE: createAsyncActionTypes("GET_ROUTE"),
  GET_DRIVER: createAsyncActionTypes("GET_DRIVER"),
  ADD_PARCEL_TO_WATCHLIST: createAsyncActionTypes("ADD_PARCEL_TO_WATCHLIST"),
  REMOVE_PARCEL_FROM_WATCHLIST: createAsyncActionTypes(
    "REMOVE_PARCEL_FROM_WATCHLIST"
  ),
  RESET_PARCEL_VIEW: "RESET_PARCEL_VIEW",
});
