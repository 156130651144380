import api from "./api";

export const fetchReturnActions = parcelCode =>
  api.get({
    path: `/v1/returns/${parcelCode}/actions`,
  });

export const fetchParcelEvents = parcelCode =>
  api.get({
    path: `/v1/returns/${parcelCode}/parcelevents`,
  });

export const fetchRelatedParcels = parcelCode =>
  api.get({
    path: `/v1/returns/${parcelCode}/findrelated`,
  });

export const fetchRoute = (
  { routeCode, depotCode, routeDate, routeNumber, routeTime },
  options
) =>
  api.get({
    path: `/v1/routes/${routeCode}`,
    query: {
      routeCode,
      depotCode,
      routeDate,
      routeNumber,
      routeTime,
    },
    options,
  });

export const fetchDriver = (driverCode, options) =>
  api.get({
    path: `/v1/driver/${driverCode}`,
    options,
  });

export const addParcelToWatchList = parcelCode =>
  api.post({
    path: `/v1/returns/${parcelCode}/actions/wtc`,
  });

export const removeParcelFromWatchList = parcelCode =>
  api.delete({
    path: `/v1/returns/${parcelCode}/actions/wtc`,
  });
