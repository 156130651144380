import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("CREATE_RETURN", {
  GET_PICKUP_COUNTRIES: createAsyncActionTypes("GET_PICKUP_COUNTRIES"),
  GET_PROFILES: createAsyncActionTypes("GET_PROFILES"),
  RESET_CREATE_RETURN: "RESET_CREATE_RETURN",
});
