import PropTypes from "prop-types";

export default function Winds({ fill = "#DC0032" }) {
  return (
    <svg viewBox="0 0 32 32" fill={fill} width="32px" height="32px">
      <path d="M19.168 3.328l5.472 0.672v9.184l-5.472 0.704zM26.528 4.256v8.672l5.472-0.672v-7.328zM11.808 2.432l5.376 0.672v11.008l-5.376 0.672v1.888h-1.856v-2.144l-4.128-2.144v13.984h2.656v3.744h1.312v1.888h-9.792v-1.888h1.312v-3.744h2.656v-26.368h1.856v4.832l4.128-2.144v-2.144h1.856zM9.952 4.8l-4.128 2.176v3.296l4.128 2.144z" />
    </svg>
  );
}

Winds.propTypes = {
  fill: PropTypes.string,
};
