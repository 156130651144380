import ActionTypes from "./actionTypes";

export const initialState = {
  csv: {},
  filters: {},
  filterValues: {},
  pagination: {
    page: 1,
    startPos: 1,
    endPos: 25,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_RETURNS_CSV.SUCCESS: {
      return {
        ...state,
        csv: action.payload,
      };
    }
    case ActionTypes.GET_RETURNS_CSV.FAILURE: {
      return {
        ...state,
        csv: initialState.csv,
      };
    }
    case ActionTypes.GET_FILTER_DATA.SUCCESS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case ActionTypes.GET_FILTER_DATA.FAILURE: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }
    case ActionTypes.GET_RETURN_ACTIONS.SUCCESS: {
      return {
        ...state,
        actions: action.payload,
      };
    }
    case ActionTypes.GET_RETURN_ACTIONS.FAILURE: {
      return {
        ...state,
        actions: initialState.actions,
      };
    }
    case ActionTypes.SET_FILTER_PAGINATION: {
      return {
        ...state,
        pagination: {
          page: action.page,
          startPos: action.startPos,
          endPos: action.endPos,
        },
      };
    }
    case ActionTypes.SET_FILTER_VALUES: {
      return {
        ...state,
        filterValues: action.values,
      };
    }
    case ActionTypes.RESET_SEARCH_RESULTS: {
      return initialState;
    }
    default:
      return state;
  }
};
