import PropTypes from "prop-types";

import { ParcelAdditionalStatus } from "../ParcelAdditionalStatus/ParcelAdditionalStatus";
import { ParcelDetails } from "../ParcelDetails/ParcelDetails";
import { ReturnStatus } from "../ReturnStatus/ReturnStatus";

export const RFIReasonParcelView = ({ parcel }) => (
  <>
    <ReturnStatus parcel={parcel} />
    <ParcelAdditionalStatus parcel={parcel} />
    <ParcelDetails data={parcel} />
  </>
);

RFIReasonParcelView.propTypes = {
  parcel: PropTypes.object,
};
