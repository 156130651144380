export const TELEPHONE_NUMBER = /^\+?\d{7,15}$/;

export const EMAIL_ADDRESS =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const DECIMAL_NUMBER = /^\d+(\.\d+)?$/;

export const WHOLE_NUMBER = /^[0-9]+$/;

export const FIRST_TWO_CHARACTERS = /^[a-zA-Z]{2}[\s\S]*$/;
