import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Header } from "@dpdgroupuk/mydpd-app";

import { RETURN_LINKS } from "../../router/constants";

export const AppHeader = ({ ...headerProps }) => {
  const [link, setLink] = useState("");
  const { businessId } = useSelector(state => state.app.auth.user);

  useEffect(() => {
    if (businessId === 1) {
      setLink("https://www.dpd.co.uk/");
    } else if (businessId === 2) {
      setLink("https://www.dpdlocal.co.uk");
    }
  }, []);

  return (
    <Header
      onClickDpdLogo={() => (window.location.href = link)}
      secondaryMenuItems={RETURN_LINKS}
      {...headerProps}
    />
  );
};
