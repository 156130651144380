import { useEffect, useState } from "react";

import { get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import { Step } from "@dpdgroupuk/mydpd-ui";

import { ReactComponent as AddToAddressBook } from "../../../../../assets/icons/add-to-addressbook.svg";
import { ActionLink } from "../../../../../components/ActionLink/ActionLink";
import { Delimiter } from "../../../../../components/Delimiter/Delimiter";
import { getSelectedCountry } from "../../../../../redux/selectors";
import {
  formatAddressBookReq,
  formattedCompareAddressbook,
} from "../../../../../utils/format";
import {
  getAddressBookFormErrors,
  getFormErrors,
  getReturnFormValues,
  mapReturnReviewDetails,
  setAddressBookBtnText,
  showAddressBookButton,
} from "../../../../../utils/forms";
import { ReviewCard } from "../ReviewCard/ReviewCard";

export const ReturnReview = ({ handleSaveToAddressBook }) => {
  const [showReturnFromAddressBookButton, setShowReturnFromAddressBookButton] =
    useState(false);
  const [showReturnToAddressBookButton, setShowReturnToAddressBookButton] =
    useState(false);
  const { values, syncErrors } = useSelector(state => state.form.return);
  const { list, returnFromSelectedAddress, returnToSelectedAddress } =
    useSelector(state => state.addressBook);
  const selectedCountry = useSelector(state => getSelectedCountry(state));
  const reviewDetails = mapReturnReviewDetails(values, selectedCountry);

  useEffect(() => {
    const returnFromAddressBookErrors = getAddressBookFormErrors(
      "returnFrom",
      syncErrors
    );
    const returnToAddressBookErrors = getAddressBookFormErrors(
      "returnTo",
      syncErrors
    );

    let returnFromAddress = returnFromSelectedAddress;
    let returnToAddress = returnToSelectedAddress;

    // If we are creating a new address, use form values rather than selectedAddress in redux store
    const access = { read: true, delete: true, edit: true };
    if (isEmpty(returnFromAddress)) {
      returnFromAddress = {
        ...getReturnFormValues("returnFrom", values),
        ...access,
      };
    }

    if (isEmpty(returnToAddress)) {
      returnToAddress = {
        ...getReturnFormValues("returnTo", values),
        ...access,
      };
    }

    const returnFromShortName = get(values, "returnFromShortName");
    const returnToShortName = get(values, "returnToShortName");

    const mappedReturnFrom = formatAddressBookReq("returnFrom", values, "0");
    const mappedReturnTo = formatAddressBookReq("returnTo", values, "1");

    const returnFromCompare = formattedCompareAddressbook(
      mappedReturnFrom,
      list
    );

    const returnToCompare = formattedCompareAddressbook(mappedReturnTo, list);

    // console.log(returnFromCompare);

    let showReturnFromBtn = showAddressBookButton(
      returnFromAddressBookErrors,
      returnFromAddress,
      returnFromShortName,
      returnFromCompare.currentValues,
      returnFromCompare.addressbookValues
    );
    let showReturnToBtn = showAddressBookButton(
      returnToAddressBookErrors,
      returnToAddress,
      returnToShortName,
      returnToCompare.currentValues,
      returnToCompare.addressbookValues
    );

    if (returnFromShortName === returnToShortName) {
      showReturnFromBtn = false;
      showReturnToBtn = false;
    }

    setShowReturnFromAddressBookButton(showReturnFromBtn);
    setShowReturnToAddressBookButton(showReturnToBtn);
  }, [values, syncErrors, list]);

  return (
    <Step
      title="Review Your Return"
      step={3}
      totalSteps={3}
      withStepCounter
      helpModalTitle={"Review Your Return"}
      complete={isEmpty(syncErrors)}
    >
      <Col xs={12}>
        <ReviewCard
          title="Return From"
          action={
            showReturnFromAddressBookButton && (
              <ActionLink
                text={setAddressBookBtnText(values, "returnFrom", list)}
                icon={AddToAddressBook}
                onClick={() => handleSaveToAddressBook("returnFrom", values)}
              />
            )
          }
          errors={getFormErrors("returnFrom", syncErrors)}
        >
          <Row>
            <Col sm={6}>
              {reviewDetails.returnFromReviewDetails.map(
                ({ inputName, value }) => (
                  <p key={inputName} className="m-0">
                    {!isEmpty(value) &&
                      (isEmpty(syncErrors) || !(inputName in syncErrors)) &&
                      value.toUpperCase()}
                  </p>
                )
              )}
            </Col>
            <Col sm={6}>
              {reviewDetails.returnFromReviewContactDetails.map(
                ({ inputName, value, title }) => (
                  <div key={title} className="mb-2">
                    {!isEmpty(value) &&
                      (isEmpty(syncErrors) || !(inputName in syncErrors)) && (
                        <>
                          <p className="m-0 font-weight-bold">{title}</p>
                          <p className="m-0">{value.toUpperCase()}</p>
                        </>
                      )}
                  </div>
                )
              )}
            </Col>
          </Row>
        </ReviewCard>
      </Col>

      <Delimiter />

      <Col xs={12}>
        <ReviewCard
          title="Return To"
          action={
            showReturnToAddressBookButton && (
              <ActionLink
                text={setAddressBookBtnText(values, "returnTo", list)}
                icon={AddToAddressBook}
                onClick={() => handleSaveToAddressBook("returnTo", values)}
              />
            )
          }
          errors={getFormErrors("returnTo", syncErrors)}
        >
          <Row>
            <Col sm={6}>
              {reviewDetails.returnToReviewDetails.map(
                ({ inputName, value }) => (
                  <p key={inputName} className="m-0">
                    {!isEmpty(value) &&
                      (isEmpty(syncErrors) || !(inputName in syncErrors)) &&
                      value.toUpperCase()}
                  </p>
                )
              )}
            </Col>
          </Row>
        </ReviewCard>
      </Col>
    </Step>
  );
};

ReturnReview.propTypes = {
  dispatch: PropTypes.func,
  handleSaveToAddressBook: PropTypes.func,
  overlay: PropTypes.object,
  banner: PropTypes.object,
};
