import {
  createActionTypes,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

export default createActionTypes("AUTH", {
  FETCH_PREFERENCES: createAsyncActionTypes("FETCH_PREFERENCES"),
  LOGOUT_USER: createAsyncActionTypes("LOGOUT_USER"),
  CLEAR: "CLEAR",
});
