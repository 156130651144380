import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { search } from "../../apis";
import ActionTypes from "./actionTypes";

export const fetchReturnsCsv = createAsyncAction(
  query => search.fetchReturnsCsv(query).then(res => res),
  ActionTypes.GET_RETURNS_CSV
);

export const fetchFilterData = createAsyncAction(
  query => search.fetchFilterData(query).then(res => res),
  ActionTypes.GET_FILTER_DATA
);

export const fetchFilterReturns = createAsyncAction(
  query => search.fetchFilterReturns(query).then(res => res),
  ActionTypes.GET_FILTER_RETURNS
);

export const fetchReturnActions = createAsyncAction(
  query => search.fetchReturnActions(query).then(res => res),
  ActionTypes.GET_RETURN_ACTIONS
);

export const setFilterPagination = paginationValues => ({
  type: ActionTypes.SET_FILTER_PAGINATION,
  ...paginationValues,
});

export const setFilterValues = values => ({
  type: ActionTypes.SET_FILTER_VALUES,
  values,
});

export const resetSearchResults = () => ({
  type: ActionTypes.RESET_SEARCH_RESULTS,
});
