import { useEffect } from "react";

import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { withProps } from "recompose";
import { compose } from "redux";
import { change, reduxForm, touch } from "redux-form";

import { AuthUtil, withAppUserPreferences } from "@dpdgroupuk/mydpd-app";
import {
  Banner,
  Card,
  Legend,
  Main,
  withOverlay,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";

import {
  fetchAddressBookTotalResults,
  insertAddressBook,
  updateAddressBook,
} from "../../../../../apis/addressBook";
import { addFuncIdToLog } from "../../../../../apis/functionalityLog";
import { RETURN_FORM } from "../../../../../constants/forms";
import { createReturnSchema } from "../../../../../models/createReturnSchema";
import { postcodeValidation } from "../../../../../models/postcodeValidation";
import { totalWeightValidation } from "../../../../../models/totalWeightValidation";
import { AddressBookActions } from "../../../../../redux";
import { getSelectedCountry } from "../../../../../redux/selectors";
import {
  formatAddressBookReq,
  formatProfiles,
} from "../../../../../utils/format";
import { getAddressBookFieldValues } from "../../../../../utils/forms";
import createValidator from "../../../../../utils/joiReduxForm";
import { ReturnFormButtonToolbar } from "../ReturnFormButtonToolbar/ReturnFormButtonToolbar";
import { ReturnFrom } from "../ReturnFrom/ReturnFrom";
import { ReturnProfiles } from "../ReturnProfiles/ReturnProfiles";
import { ReturnReview } from "../ReturnReview/ReturnReview";
import { ReturnTo } from "../ReturnTo/ReturnTo";

const ReturnForm = ({
  dispatch,
  withAutocomplete,
  overlay,
  banner,
  fetchAddressBook,
  setReturnToSelectedAddress,
  authUser,
  snackbar,
}) => {
  const { list, defaultAddress } = useSelector(state => state.addressBook);

  useEffect(() => {
    if (!isEmpty(defaultAddress)) {
      const addressBookValues = getAddressBookFieldValues(
        "returnTo",
        defaultAddress
      );
      delete addressBookValues.returnToShortName; // Remove short name as only address input fields should be populated
      setReturnToSelectedAddress(defaultAddress);
      updateFormField(addressBookValues);
    }
  }, [defaultAddress]);

  const addressBookError = () => {
    overlay.hide();
    window.scrollTo(0, 0);
    banner.removeAll();
    banner.showByType("alert", {
      message: "Unable to save address to the addressbook",
      closable: true,
      showIcon: true,
      actions: [],
    });
  };

  const addressBookSuccess = async () => {
    const { data } = await fetchAddressBookTotalResults();
    await fetchAddressBook(data.totalResults);
    await addFuncIdToLog("16ca1755a706452aa406d71437ea9e2e");

    window.scrollTo(0, 0);
    banner.removeAll();
    banner.showByType("success", {
      message: "Successfully saved address to the addressbook",
      closable: true,
      showIcon: true,
      actions: [],
    });

    overlay.hide();
  };

  const handleSaveToAddressBook = async (formSection, values) => {
    overlay.show();

    let addressBookType;
    if (formSection === "returnFrom") {
      addressBookType = "0";
    } else if (formSection === "returnTo") {
      addressBookType = "1";
    }

    const mappedValues = formatAddressBookReq(
      formSection,
      values,
      addressBookType
    );
    const selectedAddress = list.filter(
      address => address.shortName === mappedValues.shortName
    )[0];

    try {
      if (!isEmpty(selectedAddress)) {
        const addressID = selectedAddress.addressBookId;
        await updateAddressBook(addressID, { addressBookType }, mappedValues);
        return addressBookSuccess();
      }

      await insertAddressBook(mappedValues);
      return addressBookSuccess();
    } catch (error) {
      addressBookError();
    }

    overlay.hide();
  };

  const updateFormField = values => {
    if (isEmpty(values)) return;

    for (const [key, value] of Object.entries(values)) {
      dispatch(change(RETURN_FORM, key, (value || "").toUpperCase()));
      dispatch(touch(RETURN_FORM, key));
    }
  };

  return (
    <>
      <Main.Body>
        <Legend
          leftMessage="Please complete all required fields as indicated *"
          rightMessage={`${AuthUtil.getDisplayName(authUser?.user)} (Account: ${
            authUser?.user?.account
          })`}
        />
        <form id="createReturnForm">
          <Card.Stack fluid>
            <Col md={6}>
              <ReturnProfiles />
              <ReturnFrom
                updateFormField={updateFormField}
                withAutocomplete={withAutocomplete}
              />
            </Col>
            <Col md={6}>
              <ReturnTo
                updateFormField={updateFormField}
                dispatch={dispatch}
                withAutocomplete={withAutocomplete}
                handleSaveToAddressBook={handleSaveToAddressBook}
              />
              <ReturnReview handleSaveToAddressBook={handleSaveToAddressBook} />
            </Col>
          </Card.Stack>
        </form>
      </Main.Body>

      <ReturnFormButtonToolbar
        dispatch={dispatch}
        overlay={overlay}
        banner={banner}
        snackbar={snackbar}
      />
    </>
  );
};

ReturnForm.propTypes = {
  handleSubmit: PropTypes.func,
  dispatch: PropTypes.func,
  withAutocomplete: PropTypes.bool,
  overlay: PropTypes.object,
  banner: PropTypes.object,
  fetchAddressBook: PropTypes.func,
  setReturnToSelectedAddress: PropTypes.func,
  authUser: PropTypes.object,
  snackbar: PropTypes.object,
};

export default compose(
  Banner.withBanner,
  withOverlay,
  withSnackbar,
  withAppUserPreferences,
  connect(
    state => {
      const initialValues = {};
      const { profiles, pickupCountries } = state.ui.createReturn;
      const { defaultAddress } = state.addressBook;
      const selectedCountry = getSelectedCountry(state);

      if (profiles.totalResults > 0) {
        const formattedProfiles = formatProfiles(profiles.results);
        initialValues.profile = formattedProfiles[0].label;
        initialValues.profileCode = formattedProfiles[0].value;
      }

      if (pickupCountries.length === 1) {
        initialValues.returnFromCountry = pickupCountries[0].countryCode;
      }

      if (!isEmpty(defaultAddress)) {
        const addressBookValues = getAddressBookFieldValues(
          "returnTo",
          defaultAddress
        );

        for (const [key, value] of Object.entries(addressBookValues)) {
          initialValues[key] = value;
        }
      }

      return { initialValues, selectedCountry };
    },
    dispatch => ({
      fetchAddressBook: pageSize =>
        dispatch(AddressBookActions.fetchAddressBook(pageSize)),
      setReturnToSelectedAddress: address =>
        dispatch(AddressBookActions.setReturnToSelectedAddress(address)),
    })
  ),
  withProps(({ overlay, banner, selectedCountry, snackbar }) => ({
    overlay,
    banner,
    snackbar,
    withAutocomplete: selectedCountry.countryCode === "GB",
  })),
  reduxForm({
    form: RETURN_FORM,
    enableReinitialize: true,
    validate: (values, props) =>
      createValidator(createReturnSchema(props, values), [
        () =>
          postcodeValidation(
            props,
            props.selectedCountry,
            "returnFromPostcode"
          ),
        () =>
          postcodeValidation(props, props.selectedCountry, "returnToPostcode"),
        () => totalWeightValidation(props),
      ])(values, props),
  })
)(ReturnForm);
