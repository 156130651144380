import PropTypes from "prop-types";

export const Delimiter = ({ height, width }) => (
  <div style={{ height, width }} />
);

Delimiter.defaultProps = {
  height: "20px",
  width: "100%",
};

Delimiter.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
