import { get } from "lodash";
import { getFormValues } from "redux-form";
import { createSelector } from "reselect";

import { RETURN_FORM } from "../constants/forms";

export const getData = state => state.ui.createReturn;

export const getCreateReturnFormValues = state =>
  getFormValues(RETURN_FORM)(state);

const getCountries = createSelector(getData, ({ pickupCountries }) =>
  pickupCountries.sort((a, b) => {
    if (a.countryCode === "GB") return -1;
    if (b.countryCode === "GB") return 1;
    if (a.countryCode === "IE") return -1;
    if (b.countryCode === "IE") return 1;

    return a.countryName > b.countryName ? 1 : -1;
  })
);

export const getSelectedCountry = createSelector(
  getCountries,
  getCreateReturnFormValues,
  (countries, formValues) =>
    countries.find(
      ({ countryCode }) => countryCode === get(formValues, "returnFromCountry")
    ) || {}
);
