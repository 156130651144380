import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { postcodeFinder } from "../../apis";
import ActionTypes from "./actionTypes";

export const search = createAsyncAction(
  (data, options) =>
    postcodeFinder
      .searchAddresses(
        {
          pageSize: 50,
          ...data,
        },
        options
      )
      .then(({ data }) => data),
  ActionTypes.SEARCH
);
