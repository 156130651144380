import { connect } from "react-redux";
import { branch, compose, renderComponent } from "recompose";

import { AuthSelectors } from "../../../redux";
import Dashboard from "./Dashboard";
import { DashboardRedirect } from "./DashboardRedirect";

export default compose(
  connect(state => ({
    user: AuthSelectors.getAuthUser(state),
  })),
  branch(
    ({ user: { appRoles } }) =>
      appRoles.includes("rfi") || appRoles.includes("ieexceptions"),
    renderComponent(Dashboard)
  )
)(DashboardRedirect);
