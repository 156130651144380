import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { parcelView, search } from ".././../apis";
import ActionTypes from "./actionTypes";

export const fetchParcelByParcelCode = createAsyncAction(
  query =>
    search.fetchParcelByParcelCode(query).then(res => {
      const { data } = res;
      return data[0];
    }),
  ActionTypes.GET_PARCEL
);

export const fetchReturnActions = createAsyncAction(
  query => parcelView.fetchReturnActions(query).then(res => res),
  ActionTypes.GET_RETURN_ACTIONS
);

export const fetchParcelEvents = createAsyncAction(
  query => parcelView.fetchParcelEvents(query).then(res => res),
  ActionTypes.GET_PARCEL_EVENTS
);

export const fetchRelatedParcels = createAsyncAction(
  query => parcelView.fetchRelatedParcels(query).then(res => res),
  ActionTypes.GET_RELATED_PARCELS
);

export const fetchRoute = createAsyncAction(
  query => parcelView.fetchRoute(query).then(res => res),
  ActionTypes.GET_ROUTE
);

export const fetchDriver = createAsyncAction(
  query => parcelView.fetchDriver(query).then(res => res),
  ActionTypes.GET_DRIVER
);

export const addParcelToWatchList = createAsyncAction(
  query => parcelView.addParcelToWatchList(query).then(res => res),
  ActionTypes.ADD_PARCEL_TO_WATCHLIST
);

export const removeParcelFromWatchList = createAsyncAction(
  query => parcelView.removeParcelFromWatchList(query).then(res => res),
  ActionTypes.REMOVE_PARCEL_FROM_WATCHLIST
);

export const resetParcelView = () => ({
  type: ActionTypes.RESET_PARCEL_VIEW,
});
